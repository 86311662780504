export default {
    api: {
        scheme: 'https',
        domain: process.env.REACT_APP_API_DOMAIN, // eslint-disable-line
        path: '/v1/',
        timeout: process.env.REACT_APP_API_TIMEOUT, // eslint-disable-line
    },
    user: {
        storageKey: 'PDX_extranet_user',
        carouselKey: 'PDX_extranet_carousel',
    },
    l10n: {
        select: {
            clearValueText: 'Tyhjennä arvo',
            noResultsText: 'Ei tuloksia',
            placeholder: 'Valitse..',
            searchPropmtText: 'Kirjoita hakusana',
        },
    },
    reservations: {
        typeOptions: [
            { label: 'Jonotus', value: '1', status: 2 },
            { label: 'Suullinen varaus', value: '2', status: 2 },
            { label: 'Ennakkovaraussopimus', value: '3', status: 2 },
            { label: 'Käsiraha', value: '4', status: 3 },
            { label: 'Myyty', value: '5', status: 3 },
        ],
    },
}
