import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { injectGlobal } from 'styled-components'
import MainNavigation from './components/navigation/MainNavigation'
import { Fonts, Colors } from './styles/StyleConf'
import { AuthContext } from './context/AuthContext'
import { sendLogin, sendLogout } from './service/AuthService'
import Config from './config/Config'
import Footer from './components/views/Footer'

class App extends React.Component {
    constructor(props) {
        super(props)

        this.login = (callback, payload) => {
            sendLogin(this.state, payload, this.state.installation).then(res => {
                this.setState(res, () => callback && callback(false))
            })
        }

        this.logout = callback => {
            sendLogout(this.state).then(res => {
                this.setState(res, () => callback && callback(false))
            })
        }

        this.storedUser = window.sessionStorage.getItem(Config.user.storageKey)
            ? JSON.parse(window.sessionStorage.getItem(Config.user.storageKey))
            : {
                  user: null,
                  token: null,
              }

        this.state = Object.assign(
            {},
            {
                apiKey: null,
                isAuthenticated: false,
                login: this.login,
                logout: this.logout,
                loginError: null,
                installation: this.props.installation,
            },
            this.storedUser
        )
    }

    render() {
        const containerWidth = '1000px'

        injectGlobal`
            body {
                &.no-scroll {
                    overflow: hidden;
                }

                background-color: ${Colors.bgGray};
                margin: 0;
                padding: 0;
                overflow-y: scroll;

                a {
                    color: ${Colors.brandBlue};
                }
            }
        `

        const AppWrapper = styled.div`
            font-family: ${Fonts.brandFont};
            font-weight: 400;
            overflow-x: hidden;
            max-width: ${containerWidth};
            min-height: 300px;
            padding: 20px 0 40px 0;

            @media (min-width: 700px) {
                margin-right: auto;
                margin-left: auto;
            }
        `

        return (
            <AuthContext.Provider value={this.state}>
                <AppWrapper className="App">
                    <MainNavigation />
                    <Footer />
                    <div id="modalRoot" />
                </AppWrapper>
            </AuthContext.Provider>
        )
    }
}

App.propTypes = {
    installation: PropTypes.string,
}

export default App
