import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { fetchRest } from './../../utils/APIUtils'
import ReservationList from './ReservationList'
import HousingInfo from './HousingInfo'
import Button from '../input/Button'
import Carousel from './Carousel'
import { thumbify } from '../../utils/GeneralUtils'
import Config from '../../config/Config'
import { H2 } from './../../styles/Headings'
import { Colors, Fonts } from './../../styles/StyleConf'
import Header from './Header'
import Modal from '../ui/Modal'
import { ParseDate } from '../../utils/DateUtils'

const Wrapper = styled.div``
const Titles = styled.div`
    position: relative;
`
const Title = styled(H2)`
    height: 2em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
`
const Status = styled.div`
    position: absolute;
    top: 2em;
    left: 6px;
    color: ${Colors.darkGray};
    font-size: ${Fonts.large};
`
const CarouselWrapper = styled(Carousel)`
    margin-bottom: 1em;
`
const Content = styled.div`
    background: ${Colors.brandWhite};
    border: 1px solid ${Colors.uiGray};
`
const TabNavi = styled.div`
    border-bottom: 1px solid ${Colors.uiGray};
    height: 50px;
    border-top: 1px solid ${Colors.bgGray};
    border-right: 1px solid ${Colors.bgGray};
    margin: -1px -1px 0 -1px;
    background-color: ${Colors.bgGray};
    white-space: nowrap;
`
const TabNaviItem = styled(Button)`
    &&& {
        &:active,
        &:focus {
            outline: none;
            opacity: 1;
        }

        &:hover {
            color: ${props => (props.active ? Colors.brandBlack : Colors.veryDarkGray)};
            cursor: ${props => (props.active ? 'default' : 'pointer')};
        }

        background: ${props => (props.active ? Colors.brandWhite : Colors.bgGray)};
        border: ${props => (props.active ? `1px solid ${Colors.uiGray}` : 'none')};
        text-transform: uppercase;
        border-top: ${props => (props.active ? `3px solid ${Colors.brandBlue}` : 'none')};
        border-bottom-color: ${props => (props.active ? Colors.brandWhite : Colors.uiGray)};
        color: ${Colors.brandBlack};
        height: ${props => (props.active ? '51px' : '40px')};
        border-radius: 0;
        overflow: hidden;
    }
`

class HousingWrapper extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'list',
            assignments: null,
            active: null,
            housing: null,
            reservations: null,
            images: null,
            carouselVisible: localStorage.getItem(Config.user.carouselKey)
                ? localStorage.getItem(Config.user.carouselKey) === 'true'
                : true,
            zoomImage: null,
            updateTimestamp: null,
        }

        this.config = {
            schema: [
                { key: 'id', function: 'column' },
                {
                    key: 'flat_number',
                    name: 'N:o',
                    order: 'asc',
                    function: 'column',
                },
                {
                    key: 'flat_storey',
                    name: 'Kerros',
                    order: 'asc',
                    align: 'right',
                    function: 'column',
                },
                {
                    key: 'area_full',
                    name: 'Kokonaispinta-ala',
                    order: 'desc',
                    type: 'float',
                    suffix: ' m²',
                    function: 'detail',
                },
                {
                    key: 'area_living',
                    name: 'Pinta-ala',
                    order: 'desc',
                    align: 'right',
                    type: 'float',
                    suffix: ' m²',
                    function: 'column',
                },
                {
                    key: 'description_living',
                    name: 'Huoneluku',
                    order: 'asc',
                    align: 'right',
                    function: 'column',
                },
                {
                    key: 'salesprice',
                    name: 'Myyntihinta',
                    order: 'desc',
                    align: 'right',
                    type: 'float',
                    suffix: ' €',
                    function: 'column',
                },
                {
                    key: 'debtprice',
                    name: 'Velaton hinta',
                    order: 'desc',
                    align: 'right',
                    type: 'float',
                    suffix: ' €',
                    function: 'column',
                },
                {
                    key: 'reservation_status',
                    name: 'Varauksen tila',
                    order: 'asc',
                    align: 'right',
                    function: 'column',
                },
                {
                    key: 'status',
                },
                {
                    key: 'targetprice_care',
                    name: 'Hoitovastike',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'targetprice_financing',
                    name: 'Rahoitusvastike (lyh.vapaa)',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'targetprice_financing_incl_installment',
                    name: 'Rahoitusvastike (lyh.vapaan jälkeen)',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'targetprice_siterent',
                    name: 'Tontin vuokravastike',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'targetprice_total',
                    name: 'Kokonaisvastike (lyh.vapaa)',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'targetprice_total_incl_installment',
                    name: 'Kokonaisvastike (lyh.vapaan jälkeen)',
                    function: 'detail',
                    type: 'float',
                    suffix: ' €',
                },
                {
                    key: 'flat_share_count',
                    name: 'Osakemäärä',
                    function: 'detail',
                },
                {
                    key: 'flat_share_nos',
                    name: 'Osakenumerot',
                    function: 'detail',
                },
                {
                    key: 'image1',
                    function: 'image',
                },
            ],
            views: [{ key: 'list', title: 'Varauslista' }, { key: 'info', title: 'Taloyhtiön tiedot' }],
        }

        this.fetchData = this.fetchData.bind(this)
        this.toggleView = this.toggleView.bind(this)
        this.toggleCarousel = this.toggleCarousel.bind(this)
        this.zoomImage = this.zoomImage.bind(this)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData(active) {
        const fetchAssignments = fetchRest('extranet/assignments', this.props.auth, undefined, 'GET', [
            `housing_id=${this.props.id}`,
            'status=2,3,4',
        ])
        const fetchHousing = fetchRest(`extranet/housings/${this.props.id}`, this.props.auth)

        const fetchReservations = fetchRest('extranet/views/user_reservations', this.props.auth, undefined, 'GET')

        Promise.all([fetchAssignments, fetchHousing, fetchReservations])
            .then(res => {
                const assignments =
                    res[0] &&
                    res[0].data &&
                    res[0].data
                        .map(assignment => pick(assignment, this.config.schema.map(model => model.key)))
                        .map(item => {
                            const modified = {}
                            this.config.schema.forEach(model => {
                                if (model.type === 'float' && item[model.key])
                                    modified[model.key] = parseFloat(item[model.key])
                            })
                            return Object.assign({}, item, modified)
                        })
                const housing = res[1] && res[1].data && res[1].data
                const images =
                    res[1] &&
                    res[1].data &&
                    Object.keys(
                        pickBy(res[1].data, (obj, key) => {
                            return key.startsWith('image') && !key.endsWith('_caption')
                        })
                    ).map(label => ({
                        original: thumbify(res[1].data[label], 'large'),
                        thumbnail: thumbify(res[1].data[label]),
                    }))
                const reservations =
                    res[2] &&
                    res[2].data &&
                    res[2].data.map(reservation => ({
                        reservation_id: reservation.id,
                        assignment_id: reservation.assignment_id,
                    }))
                const updateTimestamp = ParseDate(undefined, 'timestampSec')

                this.setState({ assignments, housing, images, reservations, active, updateTimestamp })
            })
            .catch(err => console.error(err))
    }

    toggleView(view) {
        if (view !== this.state.view) this.setState({ view, active: null })
    }

    toggleCarousel() {
        const carouselVisible = !this.state.carouselVisible
        this.setState({ carouselVisible }, () => {
            localStorage.setItem(Config.user.carouselKey, this.state.carouselVisible)
        })
    }

    zoomImage(zoomImage) {
        this.setState({ zoomImage })
    }

    render() {
        const { id, auth } = this.props
        const {
            view,
            assignments,
            active,
            housing,
            images,
            reservations,
            carouselVisible,
            zoomImage,
            updateTimestamp,
        } = this.state

        const title = housing ? housing.name : 'Ladataan'
        const statusTxt = housing ? housing.status : ''

        return (
            <Wrapper>
                <Header view="housingDetail" auth={auth} />
                <Titles>
                    <Title title={title}>{title}</Title>
                    <Status>{statusTxt}</Status>
                </Titles>
                {images && images.length ? (
                    <CarouselWrapper
                        images={images}
                        toggler={this.toggleCarousel}
                        active={carouselVisible}
                        zoomFunc={this.zoomImage}
                    />
                ) : null}
                <Content>
                    <TabNavi>
                        {this.config.views.map(viewItem => (
                            <TabNaviItem
                                key={`viewNavi_${viewItem.key}`}
                                onClick={() => this.toggleView(viewItem.key)}
                                active={viewItem.key === view}
                            >
                                {viewItem.title}
                            </TabNaviItem>
                        ))}
                    </TabNavi>
                    {view === 'list' && (
                        <ReservationList
                            id={id}
                            schema={this.config.schema}
                            assignments={assignments}
                            reservations={reservations}
                            auth={auth}
                            refresh={this.fetchData}
                            active={active}
                            housing={housing}
                            zoomFunc={this.zoomImage}
                            key={updateTimestamp}
                        />
                    )}
                    {view === 'info' && <HousingInfo data={housing} />}
                </Content>
                {zoomImage && (
                    <Modal closeFunc={() => this.zoomImage(null)} type="image" hideTitle>
                        <img src={zoomImage} alt="" />
                    </Modal>
                )}
            </Wrapper>
        )
    }
}

HousingWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    auth: PropTypes.object.isRequired,
}

export default HousingWrapper
