import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fetchRest } from './../../utils/APIUtils'
import { FormatNumber } from './../../utils/ConversionUtils'
import { Colors, Fonts } from '../../styles/StyleConf'
import Button from '../input/Button'
import Orderer from '../ui/Orderer'
import Loader from './../Loader'
import Modal from '../ui/Modal'
import ReservationForm from '../forms/ReservationForm'
import Svg from '../ui/Svg'
import { sortGrouped, thumbify } from '../../utils/GeneralUtils'
import config from '../../config/Config'

const getReservationType = id => {
    const option = config.reservations.typeOptions.filter(option => option.value === id)[0]
    return option ? option.label : ''
}

const listConfig = {
    breakpoints: { mobile: 700 },

    filters: [
        { key: 'all', name: 'Kaikki' },
        { key: 'reserved', name: 'Varatut' },
        { key: 'free', name: 'Vapaat' },
        { key: 'own', name: 'Omat' },
    ],
}

class ReservationList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            active: null,
            sortingCol: 'flat_number',
            sortingAsc: true,
            activeFilter: 'all',
            reservationQueue: null,
            activeReservation: null,
            loadingQueue: false,
            displayModal: false,
            openingModal: false,
            customers: null,
            error: null,
        }

        this.visibleCols = () => this.props.schema.filter(n => n.function === 'column' && n.name).length
        this.colWidth = () => 100 / Math.ceil(this.visibleCols() / 2)

        this.toggleActions = this.toggleActions.bind(this)
        this.fetchReservationQueue = this.fetchReservationQueue.bind(this)
        this.fetchCompanyCustomers = this.fetchCompanyCustomers.bind(this)
        this.toggleSorting = this.toggleSorting.bind(this)
        this.toggleFilter = this.toggleFilter.bind(this)
        this.toggleRevervationModal = this.toggleRevervationModal.bind(this)
        this.createCustomer = this.createCustomer.bind(this)
        this.modifyCustomer = this.modifyCustomer.bind(this)
        this.postReservation = this.postReservation.bind(this)
        this.modifyReservation = this.modifyReservation.bind(this)
        this.removeReservation = this.removeReservation.bind(this)
        this.syncStyleForStatus = this.syncStyleForStatus.bind(this)
        this.getReservationStyles = this.getReservationStyles.bind(this)
        this.filterStatusValue = this.filterStatusValue.bind(this)
    }

    toggleActions(id, status) {
        const active = id === this.state.active ? null : id
        const shouldFetchReservationData = this.state.active !== id

        this.setState({ active, reservationQueue: null, error: null, loadingQueue: true }, () => {
            if (shouldFetchReservationData) {
                this.fetchReservationQueue(id)
                    .then(res => {
                        const fauxData = ['Myynnissä'].includes(status) && null
                        res.data || ['Myynnissä'].includes(status)
                            ? this.setState({ reservationQueue: fauxData || res.data, loadingQueue: false })
                            : this.setState({ error: 'fetchReservation', loadingQueue: false })
                    })
                    .catch(err => console.error(err))
            }
        })
    }

    fetchReservationQueue(assignment_id) {
        // extranet/views/assignment_reservations?assignment_id=<ass_id>
        return fetchRest(
            'extranet/views/assignment_reservations',
            this.props.auth,
            undefined,
            'GET',
            [`assignment_id=${assignment_id}`],
            {
                extended: 0,
            }
        )
    }

    fetchCompanyCustomers() {
        const company_id = this.props.auth.user.company.id
        return fetchRest('extranet/companycustomers/search', this.props.auth, undefined, 'GET', [
            `company_id=${company_id}`,
        ])
    }

    toggleSorting(sortingCol, order) {
        const sortingAsc = this.state.sortingCol === sortingCol ? !this.state.sortingAsc : order === 'asc'
        this.setState({ sortingCol, sortingAsc })
    }

    toggleFilter(key) {
        const activeFilter = listConfig.filters.filter(filter => filter.key === key)[0].key
        this.setState({ activeFilter })
    }

    toggleRevervationModal(fetchCustomers = false, reservationId, lead) {
        const state = {}
        if (fetchCustomers) {
            this.setState({ openingModal: true }, () =>
                this.fetchCompanyCustomers().then(res => {
                    state.customers = res && res.data ? res.data : []
                    state.displayModal = true

                    if (reservationId) {
                        const data = this.state.reservationQueue.filter(
                            reservation => reservation.reservation_id === reservationId
                        )[0]
                        data.lead = lead
                        state.activeReservation = data
                    }
                    state.openingModal = false

                    this.setState(state)
                })
            )
        } else {
            state.displayModal = !this.state.displayModal
            state.activeReservation = null
            this.setState(state)
        }
    }

    createCustomer(payload) {
        return fetchRest('extranet/companycustomers', this.props.auth, payload, 'POST')
    }

    modifyCustomer(id, payload) {
        return id && payload && fetchRest(`extranet/companycustomers/${id}`, this.props.auth, payload, 'PUT')
    }

    postReservation(res) {
        const active = this.state.active
        fetchRest(
            'extranet/reservations',
            this.props.auth,
            {
                type: res.type,
                assignment_id: active,
                company_customer_id: res.customer_id,
                company_id: this.props.auth.user.company.id,
            },
            'POST'
        ).then(() => this.props.refresh(active))
    }

    modifyReservation(res) {
        fetchRest(
            `extranet/reservations/${res.id}`,
            this.props.auth,
            {
                type: res.type,
            },
            'PUT'
        ).then(() => this.props.refresh(this.state.active))
    }

    removeReservation(res) {
        fetchRest(`extranet/reservations/${res.id}`, this.props.auth, undefined, 'DELETE').then(() =>
            this.props.refresh(this.state.active)
        )
    }

    getReservationStyles(reservation) {
        const status = reservation.status ? reservation.status : 'default'
        const reservation_status = reservation.reservation_status ? reservation.reservation_status : 'default'

        if (
            status !== 'Varattu' &&
            (reservation_status === 'Jonossa' ||
                reservation_status === 'Suullinen varaus' ||
                reservation_status === 'Ennakkovaraussopimus' ||
                reservation_status === 'Käsiraha' ||
                (status !== 4 && status !== 3 && reservation_status > 0))
        ) {
            return { color: Colors.orange, disabled: false }
        }

        if (reservation_status === 'Vapaa' && (status !== 'Myyty' && status !== 'Ei-myytävä' && status !== 'Varattu')) {
            return { color: Colors.green, disabled: false }
        }

        if (status === 'Varattu' || status === 3) {
            return { color: Colors.red, disabled: false }
        }
        /*
            Last return counts these cases:
            status === 'Alustava', status === 'Kesken'
            status === 'Myyty', status === 'Irtisanottu'
            status === 'Ei-myytävä', status === 'default'
            status === 4
        */
        return { color: Colors.uiGray, disabled: true }
    }

    UNSAFE_componentWillMount() {
        if (this.props.active) this.toggleActions(this.props.active)
    }

    syncStyleForStatus(data) {
        return data.map(item => {
            const { color, disabled } = this.getReservationStyles(item)
            item.disabled = disabled
            item.color = color
            return item
        })
    }

    filterStatusValue(assignment, key) {
        if (key !== 'reservation_status') return assignment[key]

        if (assignment.status && (assignment.status === 2 || assignment.status === 'Myynnissä')) {
            if (assignment.reservation_status) return assignment.reservation_status
            else return assignment.status
        } else if (
            assignment.status &&
            (assignment.status === 3 ||
                assignment.status === 'Varattu' ||
                assignment.status === 4 ||
                assignment.status === 'Myyty')
        ) {
            return assignment.status
        }

        return assignment.reservation_status
    }

    render() {
        const {
            active,
            activeFilter,
            sortingCol,
            sortingAsc,
            reservationQueue,
            activeReservation,
            loadingQueue,
            displayModal,
            openingModal,
            error,
            customers,
        } = this.state

        const { assignments, reservations, housing, zoomFunc, schema } = this.props

        if (!assignments) return <StyledLoader />

        const ownFromAssignments = reservations ? reservations.map(reservation => reservation.assignment_id) : []
        const ownFromReservations = reservations ? reservations.map(reservation => reservation.reservation_id) : []

        let filteredData
        switch (activeFilter) {
            case 'reserved':
                filteredData = assignments.filter(assignment => 'Varattu' === assignment.status)
                break
            case 'free':
                filteredData = assignments.filter(assignment => 'Myynnissä' === assignment.status)
                break
            case 'own':
                filteredData =
                    reservations && assignments.filter(assignment => ownFromAssignments.includes(assignment.id))
                break
            default:
                filteredData = assignments
        }

        const dataLength = {
            all: assignments.length,
            reserved: assignments.filter(assignment => 'Varattu' === assignment.status).length,
            free: assignments.filter(assignment => 'Myynnissä' === assignment.status).length,
            own: assignments.filter(assignment => ownFromAssignments.includes(assignment.id)).length,
        }

        const data = this.syncStyleForStatus(
            sortingCol !== 'flat_number'
                ? [...filteredData].sort((first, second) => sortGrouped(first, second, sortingCol, sortingAsc))
                : sortingAsc
                    ? filteredData
                    : [...filteredData].reverse()
        )

        const colWidth = this.colWidth()

        const NoResults = assignments => (
            <ListRow>
                <Col colSpan={this.visibleCols()}>{assignments ? 'Ei tuloksia' : 'Ladataan..'}</Col>
            </ListRow>
        )

        return (
            <React.Fragment>
                <Filters>
                    {listConfig.filters.map(filter => {
                        const length = `(${dataLength[filter.key]})`
                        return (
                            <Filter
                                key={`filter_${filter.key}`}
                                active={this.state.activeFilter === filter.key}
                                onClick={() => this.toggleFilter(filter.key)}
                                disabled={!assignments}
                            >
                                {`${filter.name} ${length}`}
                            </Filter>
                        )
                    })}
                </Filters>
                <List>
                    <Heading colWidth={colWidth}>
                        <tr>
                            {schema.filter(n => n.function === 'column').map(
                                item =>
                                    item.name && (
                                        <Col
                                            key={`head_${item.key}`}
                                            onClick={() => this.toggleSorting(item.key, item.order)}
                                            align={item.align}
                                            active={sortingCol === item.key}
                                            colWidth={colWidth}
                                        >
                                            <OrderIcon
                                                order={sortingCol === item.key ? (sortingAsc ? 'asc' : 'desc') : null}
                                            >
                                                {item.name}
                                            </OrderIcon>
                                        </Col>
                                    )
                            )}
                        </tr>
                    </Heading>
                    <tbody>
                        {data && data.length
                            ? data.map(assignment => {
                                  const disabled = assignment.disabled
                                  return (
                                      <React.Fragment key={assignment.id}>
                                          <ListRow
                                              onClick={() =>
                                                  !disabled && this.toggleActions(assignment.id, assignment.status)
                                              }
                                              active={active === assignment.id}
                                              disabled={disabled}
                                          >
                                              {schema.filter(n => n.function === 'column').map(item => {
                                                  if (!item.name) return null
                                                  const value =
                                                      item.type === 'float'
                                                          ? FormatNumber(assignment[item.key])
                                                          : this.filterStatusValue(assignment, item.key)

                                                  return (
                                                      <Col
                                                          key={`values_${item.key}`}
                                                          align={item.align}
                                                          color={
                                                              item.key === 'reservation_status'
                                                                  ? assignment.color
                                                                  : null
                                                          }
                                                          colWidth={colWidth}
                                                          className={item.key}
                                                      >
                                                          {value && value}
                                                          {value && item.suffix && item.suffix}
                                                      </Col>
                                                  )
                                              })}
                                          </ListRow>
                                          {active === assignment.id && (
                                              <ActionTray>
                                                  <Col colSpan={this.visibleCols()} width="100%">
                                                      <InfoTray>
                                                          {assignment && (
                                                              <React.Fragment>
                                                                  <InfoTxt>
                                                                      <tbody>
                                                                          {schema
                                                                              .filter(n => n.function === 'detail')
                                                                              .map(
                                                                                  field =>
                                                                                      assignment.hasOwnProperty(
                                                                                          field.key
                                                                                      ) && (
                                                                                          <tr
                                                                                              key={`assignment_${
                                                                                                  field.key
                                                                                              }`}
                                                                                          >
                                                                                              <th>{field.name}</th>
                                                                                              <td>
                                                                                                  {field.type ===
                                                                                                  'float'
                                                                                                      ? FormatNumber(
                                                                                                            assignment[
                                                                                                                field
                                                                                                                    .key
                                                                                                            ]
                                                                                                        )
                                                                                                      : assignment[
                                                                                                            field.key
                                                                                                        ]}
                                                                                                  {field.suffix}
                                                                                              </td>
                                                                                          </tr>
                                                                                      )
                                                                              )}
                                                                      </tbody>
                                                                  </InfoTxt>
                                                                  <InfoImgWrapper>
                                                                      {schema
                                                                          .filter(n => n.function === 'image')
                                                                          .map(image => (
                                                                              <InfoImg
                                                                                  src={thumbify(assignment[image.key])}
                                                                                  alt=""
                                                                                  key={`assignment_image_${image.key}`}
                                                                                  onClick={() =>
                                                                                      zoomFunc(assignment[image.key])
                                                                                  }
                                                                              />
                                                                          ))}
                                                                  </InfoImgWrapper>
                                                              </React.Fragment>
                                                          )}
                                                      </InfoTray>
                                                      <ReservationTable>
                                                          <thead>
                                                              <ReservationListRowHead>
                                                                  <Col width="10%">#</Col>
                                                                  <Col width="20%">Tyyppi</Col>
                                                                  <Col width="30%">Kontakti</Col>
                                                                  <Col width="30%">Varaaja</Col>
                                                                  <Col width="20%" />
                                                              </ReservationListRowHead>
                                                          </thead>
                                                          <tbody>
                                                              {reservationQueue &&
                                                                  reservationQueue.length === 0 &&
                                                                  NoResults(true)}
                                                              {reservationQueue &&
                                                                  reservationQueue.length !== 0 &&
                                                                  reservationQueue.map((reservationRow, i) => {
                                                                      const editable = ownFromReservations
                                                                          ? ownFromReservations.includes(
                                                                                reservationRow.reservation_id
                                                                            )
                                                                          : undefined
                                                                      return (
                                                                          <ReservationListRow
                                                                              editable={editable}
                                                                              key={`reservation_${i}`}
                                                                              onClick={() =>
                                                                                  editable &&
                                                                                  this.toggleRevervationModal(
                                                                                      true,
                                                                                      reservationRow &&
                                                                                          reservationRow.reservation_id,
                                                                                      i === 0
                                                                                  )
                                                                              }
                                                                          >
                                                                              <Col width="10%">{i + 1}</Col>
                                                                              <Col width="20%">
                                                                                  {getReservationType(
                                                                                      reservationRow.type
                                                                                  )}
                                                                              </Col>
                                                                              <Col width="30%">
                                                                                  {reservationRow.company_customer &&
                                                                                      reservationRow.company_customer
                                                                                          .person_name}
                                                                              </Col>
                                                                              <Col width="30%">
                                                                                  {reservationRow.company &&
                                                                                      reservationRow.company.name}
                                                                              </Col>
                                                                              <Col width="10%">
                                                                                  {editable && (
                                                                                      <EditButton>
                                                                                          <Svg
                                                                                              type="pen"
                                                                                              size="small"
                                                                                          />
                                                                                      </EditButton>
                                                                                  )}
                                                                              </Col>
                                                                          </ReservationListRow>
                                                                      )
                                                                  })}
                                                              {assignment.status === 'Myynnissä' &&
                                                                  !loadingQueue &&
                                                                  !reservationQueue &&
                                                                  !error && (
                                                                      <ReservationListRow>
                                                                          <Col colSpan="6">Ei varauksia</Col>
                                                                      </ReservationListRow>
                                                                  )}
                                                              {loadingQueue && (
                                                                  <ReservationListRow>
                                                                      <Col colSpan="6">
                                                                          <Loader />
                                                                      </Col>
                                                                  </ReservationListRow>
                                                              )}
                                                              {!reservationQueue &&
                                                                  error === 'fetchReservation' && (
                                                                      <ReservationListRow>
                                                                          <Col colSpan="6">
                                                                              <ErrorContainer>
                                                                                  Virhe varausjonon latauksessa
                                                                              </ErrorContainer>
                                                                          </Col>
                                                                      </ReservationListRow>
                                                                  )}
                                                          </tbody>
                                                      </ReservationTable>

                                                      <AddButton
                                                          onClick={() => this.toggleRevervationModal(true)}
                                                          disabled={openingModal || loadingQueue}
                                                          iconRight="angle-right"
                                                      >
                                                          {openingModal ? <Loader /> : 'Lisää varaus'}
                                                      </AddButton>
                                                      {displayModal && (
                                                          <Modal
                                                              closeFunc={() => this.toggleRevervationModal()}
                                                              title={
                                                                  activeReservation
                                                                      ? 'Muokkaa varausta'
                                                                      : 'Lisää varaus'
                                                              }
                                                          >
                                                              <ReservationForm
                                                                  customers={customers}
                                                                  reservees={
                                                                      reservationQueue &&
                                                                      reservationQueue.length &&
                                                                      reservationQueue.map(
                                                                          item =>
                                                                              item.company_customer &&
                                                                              item.company_customer.id
                                                                      )
                                                                  }
                                                                  reservation={activeReservation}
                                                                  createCustomer={this.createCustomer}
                                                                  modifyCustomer={this.modifyCustomer}
                                                                  postReservation={this.postReservation}
                                                                  modifyReservation={this.modifyReservation}
                                                                  removeReservation={this.removeReservation}
                                                                  housingStatus={housing.status}
                                                              />
                                                          </Modal>
                                                      )}
                                                  </Col>
                                              </ActionTray>
                                          )}
                                      </React.Fragment>
                                  )
                              })
                            : NoResults(assignments)}
                    </tbody>
                </List>
            </React.Fragment>
        )
    }
}

const StyledLoader = styled(Loader)`
    padding: 2em;
`
const Filters = styled.div`
    margin: 20px 10px 10px;
    white-space: nowrap;
`
const Filter = styled(Button)`
    &&& {
        &:active {
            outline: none;
        }

        &:hover {
            ${props => (props.active || props.disabled ? null : Fonts.linkHover)};
            cursor: ${props => (props.active || props.disabled ? 'default' : 'pointer')};
        }

        &:first-of-type {
            border-radius: 3px 0 0 3px;
            border-left-width: 1px;
        }

        &:last-of-type {
            border-radius: 0 3px 3px 0;
        }

        background: ${props => (props.active ? Colors.brandBlack : Colors.brandWhite)};
        text-transform: uppercase;
        color: ${props => (props.active ? Colors.brandWhite : Colors.brandBlack)};
        border-width: 1px;
        border-left-width: 0;
        border-style: solid;
        border-color: ${props => (props.active ? Colors.brandBlack : Colors.uiGray)};
        border-radius: 0;
        font-weight: normal;
        font-size: 0.8em;
    }
`

const List = styled.table`
    border-spacing: 0;
    display: block;
    width: 100%;
    min-width: 400px;
    background-color: ${Colors.brandWhite};
    padding: 10px 0;
    font-size: ${Fonts.action};

    tbody {
        display: block;
    }

    tr {
        padding: 0 1em;
    }

    td {
        padding: 10px 0;
    }

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        display: table;
        padding: 10px;

        tbody {
            display: table-row-group;
        }

        td {
            padding: 0.5em;
        }
    }
`
const Heading = styled.thead`
    vertical-align: top;
    display: block;

    tr {
        display: block;
        padding: 0 1em;
    }

    td {
        &:hover {
            cursor: pointer;
            ${Fonts.linkHover};
        }

        display: inline-block;
        width: ${props => props.colWidth}%;
        padding-top: 0.5em;
        padding-bottom: 1em;
        font-size: ${Fonts.small};
        font-weight: bold;
        white-space: normal;
        text-transform: uppercase;
    }

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        display: table-header-group;

        tr {
            display: table-row;
        }

        td {
            display: table-cell;
            width: unset;
        }
    }
`
const listRowBase = `
&:nth-of-type(odd) {
    background: ${Colors.bgBlue};
}

padding: 0 1em;
cursor: ${props => (props.disabled ? undefined : 'pointer')};
display: block;
background-color: ${props => (props.active ? `${Colors.brandBlueLighter} !important` : undefined)};

@media (min-width: ${listConfig.breakpoints.mobile}px) {
    display: table-row;
}
`
const ListRow = styled.tr`
    &:hover {
        ${props => (props.disabled || props.active ? undefined : Fonts.linkHover)};
        cursor: ${props => (props.disabled || props.active ? 'default' : 'pointer')};
    }

    ${listRowBase};
    &&& {
        background-color: ${props => (props.active ? Colors.brandBlueLighter : undefined)};
        ${props => (props.active ? 'cursor: pointer' : undefined)};
    }
`
const Col = styled.td`
    &.status {
        font-weight: bold;
    }

    white-space: nowrap;
    width: ${props => (props.width ? props.width : `${props.colWidth}%`)};
    display: inline-block;
    color: ${props => props.color};
    text-decoration: ${props => (props.active ? 'underline' : 'none')};
    vertical-align: top;

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        display: table-cell;
        text-align: ${props => (props.align === 'right' ? 'right' : 'left')};
        width: unset;
    }
`
const OrderIcon = styled(Orderer)`
    svg {
        display: none;
    }

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        svg {
            display: block;
            visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
        }
    }
`
const ActionTray = styled.tr`
    background-color: ${Colors.brandBlueLighter};
    display: block;
    padding-top: 0;

    & > td {
        text-align: right;
    }

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        display: table-row;
    }
`
const InfoTray = styled.div`
    display: flex;
    justify-content: space-between;
`
const InfoTxt = styled.table`
    border-spacing: 0;
    padding: 0 5px;
    text-align: left;
    white-space: pre-wrap;
    margin-bottom: 6px;

    th {
        vertical-align: top;
        min-width: 6em;
        padding-right: 3em;
    }

    td {
        padding: 0 0 0.5em;
    }
`
const InfoImgWrapper = styled.div`
    margin-bottom: -18px;
    padding-left: 1em;
`
const InfoImg = styled.img`
    &:hover {
        cursor: zoom-in;
    }

    max-width: 100%;
    max-height: 150px;

    @media (min-width: ${listConfig.breakpoints.mobile}px) {
        max-width: 225px;
    }
`
const ReservationTable = styled.table`
    &:before {
        content: 'Varausjono:';
        position: relative;
        display: table-caption;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 4px;
        padding: 2px 0 4px 6px;
    }

    text-align: left;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 20px;
`
const ReservationListRowHead = styled(ListRow)`
    ${listRowBase};

    &:hover {
        opacity: 1;
        cursor: default;
    }

    &&& {
        background: ${Colors.brandBlueLighter};
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    td {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        font-weight: bold;
        text-transform: uppercase;
    }
`
const ReservationListRow = styled(ListRow)`
    ${listRowBase};
    &:hover {
        ${props => (props.editable ? Fonts.linkHover : '1')};
        cursor: ${props => (props.editable ? 'pointer' : 'default')};
    }

    &:nth-of-type(even) {
        background-color: ${Colors.brandWhite};
    }

    background-color: ${Colors.bgBlue};
    cursor: default;

    td {
        white-space: normal;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
`
const ErrorContainer = styled.div``
const AddButton = styled(Button)``
const EditButton = styled.button`
    width: 20px;
    height: 20px;
    border: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-items: center;
`

ReservationList.propTypes = {
    id: PropTypes.string.isRequired,
    schema: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    assignments: PropTypes.array,
    reservations: PropTypes.array,
    refresh: PropTypes.func.isRequired,
    active: PropTypes.string,
    housing: PropTypes.shape({
        status: PropTypes.string.isRequired,
    }),
    zoomFunc: PropTypes.func.isRequired,
}

export default ReservationList
