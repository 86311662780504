import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Colors, Fonts } from './StyleConf'

export const H1 = styled.h1`
    font-size: ${Fonts.xxLarge};
    font-weight: 400;
    display: block;
    color: ${Colors.brandBlack};
    padding: 2px 6px;
    white-space: nowrap;
`

export const H2 = styled.h2`
    font-size: ${Fonts.xLarge};
    font-weight: 400;
    display: block;
    color: ${Colors.brandBlack};
    padding: 2px 6px;
    white-space: nowrap;
`

export const Label = styled.label`
    font-size: ${Fonts.small};
    user-select: none;
    cursor: ${ifProp('disabled', 'default', 'pointer')};
`
