import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Gallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery-no-icon.css'
import { Colors } from './../../styles/StyleConf'
import Svg from '../ui/Svg'

const togglerHeight = 30
const Wrapper = styled.div`
    user-select: none;
    position: relative;

    .image-gallery {
        border: ${props => (props.active ? `1px solid ${Colors.uiGray}` : 'none')};
        max-height: ${props => (props.active ? '600px' : '0px')};
        overflow: hidden;
    }

    .image-gallery-image {
        img {
            &:hover {
                cursor: zoom-in;
            }
        }

        @media (min-width: 800px) {
            height: 420px;
        }
    }

    .image-gallery-slide {
        max-width: 90%;
        padding: 10px 5% 0;
        text-align: center;

        img {
            width: auto;
            max-width: 100%;
        }
    }

    .image-gallery-thumbnails-wrapper {
        background-color: ${Colors.brandWhite};
        padding: 0 10px;
    }

    .image-gallery-thumbnail {
        &:hover {
            img {
                border-color: ${Colors.brandBlue};
            }
        }

        &.active {
            img {
                border: none;
            }
        }

        img {
            border: 1px solid ${Colors.uiGray};
        }
    }

    &&& .image-gallery-left-nav,
    &&& .image-gallery-right-nav {
        &:before {
            color: ${Colors.uiGray};
            text-shadow: 0 0 2px ${Colors.brandWhite};
            content: '<';
            font-weight: 200;
        }

        &:active {
            opacity: 0.5;
        }
    }

    &&& .image-gallery-right-nav {
        &:before {
            content: '>';
        }
    }
`
const Toggler = styled.div`
    &:hover {
        cursor: pointer;
    }

    display: inline-block;
    background: ${Colors.brandWhite};
    height: ${togglerHeight}px;
    position: absolute;
    top: -${togglerHeight - 1}px;
    right: 0;
    color: ${Colors.brandBlack};
    line-height: ${togglerHeight}px;
    text-align: center;
    padding-left: 6px;
    border: 1px solid ${Colors.uiGray};
    ${props => (props.active ? 'border-bottom: none' : `border-bottom: 1px solid ${Colors.uiGray}`)};
`
const ImageIcon = styled(Svg).attrs({ type: 'image', size: 'small' })`
    fill: ${Colors.brandBlack};
    position: relative;
    top: 4px;
`
const TogglerCount = styled.div`
    display: inline-block;
    font-weight: bold;
    height: ${togglerHeight}px;
    padding: 0 0.7em;
`
const TogglerIcon = styled.div`
    position: relative;
    top: -2px;
    display: inline-block;
    width: ${togglerHeight}px;
    height: ${togglerHeight}px;
    fill: ${Colors.brandBlack};
`

const renderItem = item => (
    <div className="image-gallery-image">
        <img src={item.original} style={{ maxHeight: '420px', objectFit: 'scale-down' }} alt="" />
    </div>
)

const Carousel = ({ images, active, toggler, className, zoomFunc }) => {
    if (!images) return null

    return (
        <Wrapper active={active} className={className}>
            <Toggler onClick={toggler}>
                <ImageIcon />
                <TogglerCount>{images.length}</TogglerCount>
                <TogglerIcon>
                    <Svg type={active ? 'angle-up' : 'angle-down'} />
                </TogglerIcon>
            </Toggler>
            <Gallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                renderItem={renderItem}
                onClick={e => zoomFunc(e.target.src)}
            />
        </Wrapper>
    )
}

Carousel.propTypes = {
    images: PropTypes.array,
    active: PropTypes.bool,
    toggler: PropTypes.func.isRequired,
    className: PropTypes.string,
    zoomFunc: PropTypes.func.isRequired,
}

export default Carousel
