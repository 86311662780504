import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ text, className }) => {
    return <div className={className}>{text}</div>
}

Loader.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
}

Loader.defaultProps = {
    text: 'Ladataan..',
}

export default Loader
