import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import merge from 'lodash/merge'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import Config from '../../config/Config'
import { Colors } from '../../styles/StyleConf'
import 'react-select/dist/react-select.css'

class Select extends React.Component {
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props, nextProps)
    }

    render() {
        const customOptions = merge({}, Config.l10n.select, this.props.config)

        const svars = {
            height: '24px',
            fontSize: '.8em',
        }

        const Select = styled(ReactSelect)`
            & .Select-control {
                .Select-loading-zone {
                    .Select-loading {
                        width: 10px;
                        height: 10px;
                        top: -1px;
                    }
                }
                .Select-input {
                    & > input {
                        padding: 0;
                    }

                    height: ${svars.height};
                }

                .Select-placeholder,
                .Select-value {
                    line-height: 1.8 !important;
                    font-size: ${svars.fontSize};
                }

                .Select-input {
                    font-size: ${svars.fontSize};
                    padding-top: 3px;
                }

                height: ${svars.height};
                border-radius: 2px;
                border-color: ${Colors.lightGray};
            }

            & .Select-placeholder {
                line-height: 1em;
                height: ${svars.height};
            }
        `

        return (
            <Select
                name={this.props.name}
                id={this.props.id}
                options={this.props.options}
                {...customOptions}
                onChange={this.props.callback}
                value={this.props.value}
                searchable={this.props.searchable}
                clearable={this.props.clearable}
                resetValue={this.props.resetValue}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
                className={this.props.className}
            />
        )
    }
}

Select.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.array,
    config: PropTypes.object,
    callback: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    resetValue: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
}

Select.defaultProps = {
    resetValue: null,
}

export default Select
