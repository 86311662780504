export const Colors = {
    brandBlack: '#38383B',
    brandWhite: '#FFFFFF',
    brandBlue: '#00a8eb',
    bgGray: '#F5F4F0',
    bgBlue: '#F7F7FA',
    actionBlue: '#1CA2E5',
    uiGray: '#BFBFBF',
    success: '#36AC65',
    error: '#D11515',
    brandBlueLight: '#65bae991',
    brandBlueLighter: '#65bae947',
    brandOrange: '#f19c2d',
    darkGray: '#666',
    veryDarkGray: '#9e9e9e',
    lightGray: '#ccc',
    errorRed: '#ce2f51',
    orange: 'orange',
    red: '#D04848',
    green: '#5ba51a',
}

export const UI = {
    zModal: 1000,
    zShrinkwrap: 900,
}

export const Fonts = {
    brandFont: '"Source Sans Pro", Arial, sans-serif',
    actionFont: 'Roboto, Arial, sans-serif',
    familyBrochure: 'Tahoma',
    small: '13px',
    action: '14px',
    medium: '16px',
    large: '18px',
    xLarge: '24px',
    xxLarge: '35px',
    linkHover: 'opacity: .7',
    linkActive: 'opacity: .4',
}
