export const DecodeEntities = input => {
    const convTable = {
        '&euro;': '€',
        '¤': '€',
        'm&sup2;': 'm²',
    }

    let output = input

    for (const key in convTable) {
        const value = convTable[key]

        if (input.includes(key)) {
            output = output.replace(new RegExp(key, 'g'), value)
        }
    }

    return output || input
}

export const FormatNumber = (input, decimals = 2, empty = true, trailingZero = false) => {
    let num = parseFloat(input)

    if (isNaN(num)) return ''

    num = num.toFixed(decimals)

    if (!trailingZero) {
        const fractions = num.substring(num.indexOf('.'))
        if (!parseFloat(fractions)) {
            num = num.split('.')[0]
        }
    }

    const integer = num.split('.')[0]
    for (let space of [3, 6, 9, 12]) {
        if (integer.length > space) {
            const pos = integer.length - space
            num = [num.slice(0, pos), ' ', num.slice(pos)].join('') // NON-BREAKING THIN SPACE!!
        }
    }

    num = num.replace('.', ',')

    return !empty && !parseFloat(num) ? null : num
}

export const AbbreviateAddress = address => {
    return address.replace(/\d+$/, '').trim()
}
