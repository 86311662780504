export const thumbify = (src, size) => {
    if (!src) return null

    let slug
    switch (size) {
        case 'large':
            slug = '1920'
            break
        case 'big':
            slug = '640'
            break
        default:
            slug = '128'
            break
    }

    let thumbSrc
    // replace: /installation/ -> /640/installation/
    thumbSrc = `${src.replace(/\/([a-z0-9_]+)\//, '/' + slug + '/$1/')}`

    return thumbSrc
}

export const sortGrouped = (first, second, col, orderAsc) => {
    const a = first[col] || null
    const b = second[col] || null

    return orderAsc
        ? (a === null) - (b === null) || +(a > b) || -(a < b)
        : (a === null) - (b === null) || -(a > b) || +(a < b)
}
