import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../styles/StyleConf'

const Req = () => {
    const Tag = styled.span`
        color: ${Colors.errorRed};
    `

    return <Tag>*</Tag>
}

export default Req
