import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '../input/Button'
import Logo from '../ui/Logo'
import LogoutButton from '../login/LogoutButton'

const Header = ({ view, auth }) => {
    const breakpoint = 600

    const Wrapper = styled.div`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 8em;
        user-select: none;

        .header-link {
            position: relative;
            top: 80px;
            margin: 0 10px;

            @media (min-width: ${breakpoint}px) {
                top: 0;
            }
        }

        @media (min-width: ${breakpoint}px) {
            align-items: center;
            justify-content: space-between;
            height: 6em;
        }
    `

    return (
        <Wrapper>
            <Link
                to={`/${auth.installation}/taloyhtiot`}
                className="header-link"
                style={{ visibility: view === 'housingDetail' ? 'visible' : 'hidden' }}
            >
                <Button iconLeft="angle-left">Taloyhtiöt</Button>
            </Link>
            <Logo breakpoint={breakpoint} />
            <LogoutButton auth={auth} />
        </Wrapper>
    )
}

Header.propTypes = {
    view: PropTypes.string.isRequired,
    auth: PropTypes.shape({}).isRequired,
}

export default Header
