import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Colors, Fonts } from '../../styles/StyleConf'
import Svg from '../ui/Svg'

const Button = ({ type, iconLeft, iconRight, iconSize, children, ...rest }) => {
    const hasIcon = iconLeft || iconRight
    let baseStyles
    switch (type) {
        case 'secondary':
            baseStyles = `
                font-family: ${Fonts.actionFont};
                font-size: ${Fonts.action};
                font-weight: bold;
                background-color: ${Colors.brandWhite};
                padding: 15px 21px;
                border-radius: 3px;
                border: 1px solid ${Colors.uiGray};
                color: ${Colors.brandBlack};
                text-transform: uppercase;
            `
            break
        default:
            baseStyles = `
                font-family: ${Fonts.actionFont};
                font-size: ${hasIcon ? Fonts.small : Fonts.action};
                font-weight: bold;
                background-color: ${Colors.actionBlue};
                padding: ${hasIcon ? '10px' : '15px 21px'};
                border-radius: 3px;
                border: 1px solid ${Colors.actionBlue};
                display: inline-block;
                ${hasIcon && 'align-items: center'};
                text-transform: uppercase;
            `
    }

    const Button = styled.button`
        &:active {
            opacity: 0.7;
            border-radius: 0px;
        }

        &:focus {
            outline: 2px dotted ${Colors.brandBlue};
        }

        user-select: none;
        color: white;
        opacity: ${ifProp('disabled', 0.5, 1)};
        cursor: ${ifProp('disabled', 'default', 'pointer')};
        max-width: 20em;
        overflow-x: hidden;
        white-space: ${ifProp('multiline', 'wrap', 'nowrap')};
        text-overflow: ellipsis;
        ${baseStyles};
    `
    const Inner = styled.div`
        display: ${hasIcon ? 'flex' : 'inline-block'};
        align-items: center;
    `
    const Icon = styled(Svg)`
        display: inline-block;
        fill: ${Colors.brandWhite};
        height: 20px;
    `
    const IconWrapper = styled.span`
        ${iconLeft && 'margin-right: 10px'};
        ${iconRight && 'margin-left: 10px'};
    `

    const IconLeft = iconLeft ? (
        <IconWrapper>
            <Icon type={iconLeft} iconSize={iconSize} />
        </IconWrapper>
    ) : null
    const IconRight = iconRight ? (
        <IconWrapper>
            <Icon type={iconRight} iconSize={iconSize} />
        </IconWrapper>
    ) : null

    return (
        <Button {...rest}>
            <Inner>
                {IconLeft}
                {children}
                {IconRight}
            </Inner>
        </Button>
    )
}

Button.propTypes = {
    type: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    iconSize: PropTypes.string,
    children: PropTypes.any,
}

export default Button
