const FetchLoader = (data, error, debug, auth, path) => {
    if (error) {
        console.error(error.code, JSON.stringify(debug))

        if (error.code === 401 && !path.includes('login')) {
            auth.logout(() => (window.location = `/${auth.installation}/kirjaudu`))
        }
        return { error }
    }

    return data
}

export default FetchLoader
