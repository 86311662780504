import React from 'react'

export const authModel = {
    apiKey: null,
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
    user: null,
    token: null,
}

export const AuthContext = React.createContext(authModel)
