import React from 'react'
import styled from 'styled-components'
import { Fonts, Colors } from '../../styles/StyleConf'

const Footer = () => {
    const Wrapper = styled.div`
        margin: 1em 10% 2em;
        font-size: ${Fonts.action};
        line-height: 1.2em;
        text-align: center;
        color: ${Colors.veryDarkGray};

        p {
            margin: 0.5em 0;
        }
    `

    return (
        <Wrapper>
            <p>PDX Extranet on rakennusliikkkeiden välittäjille tarjoama palvelu uudiskohteiden myyntiin</p>
            <p>
                PDX-tuki palvelee arkisin klo 09:00-16:00 - <a href="mailto:tuki@pdx.fi">tuki@pdx.fi</a> - 010 800 820
            </p>
        </Wrapper>
    )
}

export default Footer
