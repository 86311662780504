import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H1 } from '../../styles/Headings'
import logo from '../../images/pdx_logo.png'
import { Colors, Fonts } from '../../styles/StyleConf'

const Logo = ({ breakpoint }) => {
    const LogoWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `
    const Heading = styled(H1)`
        font-weight: 300;
        margin: 0 0 0 8px;
        display: flex;
    `
    const Image = styled.img`
        border-radius: 15px;
        display: flex;
    `
    const Identity = styled.div`
        ${breakpoint &&
            `@media (min-width: ${breakpoint}px) {
                position: relative;
            }
            position: absolute
            `};
    `

    const Slogan = styled.p`
        margin: 6px 0;
        color: ${Colors.veryDarkGray};
        font-size: ${Fonts.action};
        text-align: center;
        display: none;

        ${breakpoint
            ? `@media (min-width: ${breakpoint}px) {
            display: block;
        }`
            : `display: block;
        `};
    `

    return (
        <Identity>
            <LogoWrapper>
                <Image src={logo} alt="PDX" />
                <Heading>Extranet</Heading>
            </LogoWrapper>
            <Slogan>Yhdistää rakennusliikkeen ja välittäjät</Slogan>
        </Identity>
    )
}

Logo.propTypes = {
    breakpoint: PropTypes.number,
}

export default Logo
