import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../ui/Logo'
import { Colors, Fonts } from '../../styles/StyleConf'
import Button from '../input/Button'

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            submitted: false,
        }

        this.username = React.createRef()
        this.password = React.createRef()

        this.submitForm = this.submitForm.bind(this)
    }

    submitForm(e) {
        e.preventDefault()
        this.props.auth.login(null, {
            username: this.username.current.value,
            password: this.password.current.value,
        })
    }

    render() {
        const { location, auth } = this.props
        const { username, password, submitted } = this.state

        const Wrapper = styled.div`
            max-width: 500px;
            min-width: 300px;
            min-height: 300px;
            margin: 10% auto 0;
            text-align: center;
        `
        const Form = styled.form`
            background-color: ${Colors.bgBlue};
            border: 1px solid ${Colors.uiGray};
            margin: 30px auto 10px;
            padding: 40px 20px;
        `
        const Text = styled.input.attrs({ type: 'text' })`
            max-width: 300px;
            width: calc(100% - 2em);
            border: 1px solid ${Colors.uiGray};
            width: 80%;
            height: 40px;
            font-size: ${Fonts.large};
            padding: 0 1em;
            margin: 0 auto 10px;
            display: block;
        `
        const Pass = styled(Text).attrs({ type: 'password' })``

        const Submit = styled(Button).attrs({ type: 'submit' })`
            margin: 10px auto;
            display: block;
        `
        const Info = styled.p`
            color: ${Colors.veryDarkGray};
            font-size: ${Fonts.action};
            margin-bottom: 0;
        `
        const Error = styled.div`
            margin-top: 1em;
            color: ${Colors.errorRed};
        `

        const from = location.state && location.state.referrer ? location.state.referrer : { pathname: '/' }

        let errorMsg
        switch (auth.loginError) {
            case 400:
            case 401:
            case 403:
                errorMsg = 'Kirjautuminen epäonnistui. Tarkista käyttäjätiedot sekä osoite, kiitos.'
                break
            case null:
            case undefined:
                errorMsg = null
                break
            default:
                errorMsg = 'Yhteysvirhe - yritä hetken kuluttua uudestaan, kiitos.'
        }

        return auth.isAuthenticated && from ? (
            location.state ? (
                <Redirect to={from} />
            ) : (
                <Redirect to={{ pathname: `/${auth.installation}/taloyhtiot` }} />
            )
        ) : (
            <Wrapper>
                <Logo />
                <Form onSubmit={this.submitForm}>
                    <Text
                        innerRef={this.username}
                        name="username"
                        placeholder="käyttäjätunnus"
                        tabIndex="1"
                        defaultValue={username}
                        autoFocus
                    />
                    <Pass
                        innerRef={this.password}
                        name="password"
                        placeholder="salasana"
                        tabIndex="2"
                        defaultValue={password}
                    />
                    <Submit tabIndex="3" disabled={submitted}>
                        Kirjaudu
                    </Submit>
                    {errorMsg && <Error>{errorMsg}</Error>}
                </Form>
                <Info>Mikäli tarvitset tunnukset järjestelmään, olethan yhteydessä rakennusliikkeeseen</Info>
            </Wrapper>
        )
    }
}

Login.propTypes = {
    auth: PropTypes.shape({
        login: PropTypes.func.isRequired,
    }),
    location: PropTypes.shape({
        state: PropTypes.shape({
            referrer: PropTypes.object,
        }),
    }),
}

export default withRouter(Login)
