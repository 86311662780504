import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Colors, Fonts, UI } from '../../styles/StyleConf'
import Button from '../input/Button'
import Svg from './Svg'

class Modal extends React.Component {
    constructor(props) {
        super(props)

        this.el = document.createElement('div')
        this.modalRoot = document.getElementById('modalRoot')
    }

    componentDidMount() {
        document.body.classList.add('no-scroll')
        this.modalRoot.appendChild(this.el)
    }

    componentWillUnmount() {
        document.body.classList.remove('no-scroll')
        this.modalRoot.removeChild(this.el)
    }

    render() {
        const { children, title, closeFunc, type, hideTitle } = this.props

        let wrapperStyles, shrinkwrapStyles, contentStyles
        switch (type) {
            case 'image':
                wrapperStyles = css`
                    text-align: center;
                    cursor: zoom-out;

                    img {
                        cursor: zoom-out;
                        max-height: 95vh;
                    }

                    @media (min-width: 500px) {
                        width: 100%;
                    }
                `
                shrinkwrapStyles = css`
                    cursor: zoom-out;
                `
                break
            default:
                wrapperStyles = css`
                    width: 100%;
                    background-color: ${Colors.bgGray};
                    border: 1px solid ${Colors.lightGray};
                    overflow-y: hidden;

                    @media (min-width: 500px) {
                        left: calc(50% - (400px / 2));
                        max-width: 400px;
                    }

                    @media (min-height: 600px) {
                        top: 10%;
                    }
                `
                shrinkwrapStyles = css`
                    cursor: pointer;
                `
                contentStyles = css`
                    overflow-y: auto;
                `
                contentStyles = css`
                    overflow-y: auto;
                `
        }

        const Shrinkwrap = styled.div`
            ${shrinkwrapStyles};
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1000vh;
            opacity: 0.9;
            background-color: white;
            z-index: ${UI.zShrinkwrap};
        `
        const Wrapper = styled.div`
            ${wrapperStyles};
            position: fixed;
            top: 1%;
            left: 0;
            max-height: 95vh;
            z-index: ${UI.zModal};

            img {
                max-width: 100%;
            }
        `
        const Title = styled.div`
            background: ${Colors.brandBlue};
            color: ${Colors.brandWhite};
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
        `
        const TitleTxt = styled.span`
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 1em;
        `
        const CloseBtn = styled(Button)`
            && {
                &:hover {
                    ${Fonts.linkHover};
                }

                width: 40px;
                height: 40px;
                fill: white;
                padding: 3px 0 0 0;
            }
        `
        const Content = styled.div`
            ${contentStyles};
            max-height: 80vh;

            @media (min-height: 600px) {
                max-height: 78vh;
            }
        `

        return ReactDOM.createPortal(
            <React.Fragment>
                <Shrinkwrap onClick={closeFunc} />
                <Wrapper onClick={type === 'image' ? closeFunc : undefined}>
                    {!hideTitle && (
                        <Title>
                            <TitleTxt title={title}>{title}</TitleTxt>
                            <CloseBtn onClick={closeFunc}>
                                <Svg type="close" />
                            </CloseBtn>
                        </Title>
                    )}
                    <Content>{children}</Content>
                </Wrapper>
            </React.Fragment>,
            this.el
        )
    }
}

Modal.propTypes = {
    children: PropTypes.object.isRequired,
    title: PropTypes.string,
    closeFunc: PropTypes.func.isRequired,
    type: PropTypes.string,
    hideTitle: PropTypes.bool,
}

export default Modal
