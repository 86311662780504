import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fetchRest } from '../../utils/APIUtils'
import Loader from './../Loader'
import { Fonts, Colors } from '../../styles/StyleConf'
import { H2 } from '../../styles/Headings'
import { thumbify } from '../../utils/GeneralUtils'
import Header from './Header'
import { ParseDate } from '../../utils/DateUtils'
import Svg from '../ui/Svg'

class HousingList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            housings: null,
            logo: null,
            fetchingHousings: false,
        }
    }

    componentDidMount() {
        this.setState({ fetchingHousings: true }, () => {
            const fetchHousings = fetchRest('extranet/housings/search', this.props.auth, undefined, 'GET', [
                'status=2,3',
            ])
            const fetchLogo = fetchRest('extranet/offices/1', this.props.auth)

            Promise.all([fetchHousings, fetchLogo])
                .then(res => {
                    const [housings, logo] = res
                    this.setState({ housings: housings.data, logo: logo.data, fetchingHousings: false })
                })
                .catch(err => console.error(err))
        })
    }

    render() {
        const { auth } = this.props
        const { housings, logo, fetchingHousings } = this.state

        const borderWidth = 4
        const borderColor = Colors.bgBlue

        const List = styled.div`
            background-color: ${Colors.brandWhite};
            padding: 0.5em 0;
            min-height: 310px;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid ${Colors.uiGray};
        `
        const StyledLoader = styled(Loader)`
            padding: 2em;
        `
        const Heading = styled(H2)`
            text-transform: uppercase;
        `
        const ListItemBase = `
            display: inline-block;
            padding: 0.5em 2%;
            width: 100%;
            white-space: nowrap;

            @media (min-width: 550px) {
                width: 46%;
            }
            @media (min-width: 980px) {
                width: 30%;
                padding-right: 1.6%;
                padding-left: 1.6%;
            }
        `
        const ListItem = styled.div`
            ${ListItemBase};
        `
        const LogoWrapper = styled.div`
            height: 71%;
            display: flex;
            justify-content: center;
            align-items: center;
        `
        const Logo = styled.img`
            padding: 2em;
            width: calc(100% - ${borderWidth * 2}px);
            max-width: 100%;
            max-height: 100%;
        `
        const ListLinkItem = styled(Link)`
            &:hover {
                ${Fonts.linkHover};
            }

            ${ListItemBase};
        `
        const ImageWrapper = styled.div`
            position: relative;
            display: flex;
            align-items: flex-start;
            width: 100%;
            min-height: 200px;
            height: 71%;
            background-color: ${borderColor};
            overflow: hidden;
            border-top: ${borderWidth}px solid ${borderColor};
            border-bottom: ${borderWidth}px solid ${borderColor};
        `
        const overlay = `
            position: absolute;
            background: rgba(0, 0, 0, 0.2);
            padding: 2px 6px;
            color: ${Colors.brandWhite};
            text-transform: uppercase;
            font-size: ${Fonts.small};
        `
        const Status = styled.div`
            ${overlay};
            top: 1em;
            left: 4px;
        `
        const Showing = styled.div`
            ${overlay};
            top: 4em;
            left: 4px;

            span {
                padding-left: 14px;
            }
        `
        const Showing2 = styled(Showing)`
            top: 6em;
        `
        const Calendar = styled(Svg).attrs({ type: 'calendar' })`
            fill: white;
            width: 10px;
            position: absolute;
            top: 2px;
        `
        const Metrics = styled.div`
            ${overlay};
            right: 4px;
            bottom: 1em;
        `
        const Image = styled.img`
            align-self: center;
            width: calc(100% - ${borderWidth * 2}px);
            border-right: ${borderWidth}px solid ${borderColor};
            border-left: ${borderWidth}px solid ${borderColor};
        `
        const Text = styled.div`
            & > div {
                text-overflow: ellipsis;
                overflow-x: hidden;
            }

            width: 90%;
            display: inline-block;
            padding: 0.5em 1em;
            vertical-align: top;
            color: ${Colors.brandBlack};
            font-size: ${Fonts.action};
        `
        const ItemHeading = styled.div`
            font-size: ${Fonts.medium};
            font-weight: bold;
        `
        const City = styled.span`
            text-transform: uppercase;
        `

        return (
            <React.Fragment>
                <Header view="housingList" auth={auth} />
                <Heading>Taloyhtiöt</Heading>
                <List>
                    {logo &&
                        logo.logo && (
                            <ListItem>
                                <LogoWrapper>
                                    <Logo src={logo.logo} alt="" />
                                </LogoWrapper>
                            </ListItem>
                        )}
                    {housings ? (
                        housings.map(housing => {
                            const img = housing.image1
                            const thumb = thumbify(img, 'big')
                            let openDate1 = housing.showing_begin_date ? ParseDate(housing.showing_begin_date, 'd') : ''
                            openDate1 += housing.showing_begin_time
                                ? ` ${housing.showing_begin_time.substring(
                                      0,
                                      housing.showing_begin_time.lastIndexOf(':')
                                  )}`
                                : ''
                            openDate1 += housing.showing_end_time
                                ? ` - ${housing.showing_end_time.substring(
                                      0,
                                      housing.showing_end_time.lastIndexOf(':')
                                  )}`
                                : ''
                            let openDate2 = housing.showing_begin_date2
                                ? ParseDate(housing.showing_begin_date2, 'd')
                                : ''
                            openDate2 += housing.showing_begin_time2
                                ? ` ${housing.showing_begin_time2.substring(
                                      0,
                                      housing.showing_begin_time2.lastIndexOf(':')
                                  )}`
                                : ''
                            openDate2 += housing.showing_end_time2
                                ? ` - ${housing.showing_end_time2.substring(
                                      0,
                                      housing.showing_end_time2.lastIndexOf(':')
                                  )}`
                                : ''

                            return (
                                <ListLinkItem
                                    key={`housing_${housing.id}`}
                                    to={{
                                        pathname: `/${auth.installation}/varaukset/${housing.id}`,
                                    }}
                                >
                                    <ImageWrapper>
                                        {housing.status && <Status>{housing.status}</Status>}
                                        {openDate1 && (
                                            <Showing>
                                                <Calendar type="calendar" />
                                                <span>{openDate1}</span>
                                            </Showing>
                                        )}
                                        {openDate2 && (
                                            <Showing2>
                                                <Calendar type="calendar" />
                                                <span>{openDate2}</span>
                                            </Showing2>
                                        )}
                                        <Image src={thumb} alt="" />
                                        <Metrics title="Vapaana / Varattu / Myyty">{`${housing.flats_free ||
                                            '0'} / ${housing.flats_reserved || '0'} / ${housing.flats_sold ||
                                            '0'}`}</Metrics>
                                    </ImageWrapper>
                                    <Text>
                                        <ItemHeading>{housing.name}</ItemHeading>
                                        <div>{housing.address}</div>
                                        <div>
                                            {housing.zipcode} <City>{housing.city}</City>
                                        </div>
                                    </Text>
                                </ListLinkItem>
                            )
                        })
                    ) : (
                        <StyledLoader text={fetchingHousings ? undefined : 'Ei tuloksia'} />
                    )}
                </List>
            </React.Fragment>
        )
    }
}

HousingList.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default HousingList
