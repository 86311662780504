import { fetchRest } from '../utils/APIUtils'
import Config from './../config/Config'

export const sendLogin = (auth, payload, installation) => {
    return fetchRest('extranet/account/login', auth, Object.assign({}, payload, { installation }), 'POST')
        .then(res => {
            if (res.error) {
                return {
                    loginError: res.error.code,
                }
            }

            const userObj = Object.assign({}, auth, {
                user: {
                    company: res.data.company,
                    contact: res.data.contact,
                    contact_housings: res.data.contact_housings,
                },
                apiKey: res.data.api_key,
                token: res.data.token,
                isAuthenticated: true,
                installation,
            })

            sessionStorage.setItem(Config.user.storageKey, JSON.stringify(userObj))

            return userObj
        })
        .catch(err => console.error(err))
}

export const sendLogout = auth => {
    return fetchRest('extranet/account/logout', auth, { contact_id: auth.user.contact.id }, 'POST').then(() => {
        sessionStorage.removeItem(Config.user.storageKey)

        return Object.assign({}, auth, {
            user: null,
            apiKey: null,
            token: null,
            isAuthenticated: false,
            loginError: null,
        })
    })
}
