import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Svg from './Svg'
import { Colors } from '../../styles/StyleConf'

const Orderer = ({ order, align, className, children }) => {
    const common = css`
        width: 10px;
        display: block;
    `
    const Outer = styled.div`
        white-space: nowrap;
    `
    const Wrapper = styled.div`
        display: inline-block;
        position: relative;
        top: 5px;
        ${align === 'right' ? 'right: 7px;' : 'left: 6px'};
        ${align === 'right' ? 'padding-left: .5em' : 'padding-right: .5em'};
    `
    const Up = styled(Svg).attrs({ type: 'angle-up' })`
        fill: ${order === 'desc' ? Colors.brandBlack : Colors.uiGray};
        margin-bottom: 2px;
        ${common};
    `
    const Down = styled(Svg).attrs({ type: 'angle-down' })`
        fill: ${order === 'asc' ? Colors.brandBlack : Colors.uiGray};
        ${common};
    `

    return align === 'right' ? (
        <Outer className={className}>
            <Wrapper>
                <Up />
                <Down />
            </Wrapper>
            <span>{children}</span>
        </Outer>
    ) : (
        <Outer className={className}>
            <span>{children}</span>
            <Wrapper>
                <Up />
                <Down />
            </Wrapper>
        </Outer>
    )
}

Orderer.propTypes = {
    order: PropTypes.string,
    align: PropTypes.string,
    children: PropTypes.string,
    className: PropTypes.string,
}

export default Orderer
