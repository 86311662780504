import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Svg = ({ type, size, className }) => {
    let path, sizes, inline, attrs
    const paths = {
        pdx:
            'M 0 15.4L 0 0L 6.622 0C 10.494 0 13.002 2.002 13.002 5.39L 13.002 5.434C 13.002 9.086 10.208 11 6.402 11L 4.268 11L 4.268 15.4L 0 15.4ZM 4.26794 7.656L 6.31394 7.656C 7.80994 7.656 8.75594 6.842 8.75594 5.632L 8.75594 5.588C 8.75594 4.268 7.80994 3.586 6.29194 3.586L 4.26794 3.586L 4.26794 7.656ZM 14.7274 15.4L 14.7274 0L 20.6894 0C 26.1674 0 29.3574 3.168 29.3574 7.612L 29.3574 7.656C 29.3574 12.1 26.1234 15.4 20.6014 15.4L 14.7274 15.4ZM 20.7335 3.76199L 18.9955 3.76199L 18.9955 11.616L 20.7335 11.616C 23.3075 11.616 25.0015 10.208 25.0015 7.72199L 25.0015 7.67799C 25.0015 5.21399 23.3075 3.76199 20.7335 3.76199ZM 39.7728 0L 44.5688 0L 39.5088 7.502L 44.7888 15.4L 39.8828 15.4L 36.9348 10.736L 33.9648 15.4L 29.1688 15.4L 34.4488 7.568L 29.3888 0L 34.2948 0L 37.0008 4.334L 39.7728 0ZM 50.0783 13.332L 50.0783 9.504L 46.2063 9.504L 46.2063 5.808L 50.0783 5.808L 50.0783 1.98L 53.9063 1.98L 53.9063 5.808L 57.7783 5.808L 57.7783 9.504L 53.9063 9.504L 53.9063 13.332L 50.0783 13.332Z',
        angle:
            'M 13.7 0.3C 13.3 -0.1 12.7 -0.1 12.3 0.3L 7 5.6L 1.7 0.3C 1.3 -0.1 0.7 -0.1 0.3 0.3C -0.1 0.7 -0.1 1.3 0.3 1.7L 6.3 7.7C 6.5 7.9 6.8 8 7 8C 7.2 8 7.5 7.9 7.7 7.7L 13.7 1.7C 14.1 1.3 14.1 0.7 13.7 0.3Z',
        close: [
            'M 14.4186 15.6186C 14.1004 15.6183 13.7954 15.4917 13.5706 15.2666L 0.354567 2.06657C -0.118189 1.59381 -0.118189 0.827323 0.354567 0.354567C 0.827323 -0.118189 1.59381 -0.118189 2.06657 0.354567L 15.2666 13.5546C 15.6093 13.8977 15.7118 14.4135 15.5262 14.8616C 15.3407 15.3098 14.9036 15.6021 14.4186 15.6026L 14.4186 15.6186Z',
            'M 1.19898 15.5701C 0.713953 15.5696 0.276874 15.2773 0.0913203 14.8291C -0.0942337 14.381 0.00822693 13.8652 0.350979 13.5221L 13.551 0.322068C 14.0238 -0.118506 14.7606 -0.105506 15.2176 0.351473C 15.6746 0.808452 15.6876 1.54525 15.247 2.01807L 2.04698 15.2181C 1.82215 15.4432 1.51713 15.5698 1.19898 15.5701Z',
        ],
        plus:
            'M 12.0556 5.44444L 7.77778 5.44444L 7.77778 1.16667C 7.77778 0.544444 7.23333 0 6.61111 0C 5.98889 0 5.44444 0.544444 5.44444 1.16667L 5.44444 5.44444L 1.16667 5.44444C 0.544444 5.44444 0 5.98889 0 6.61111C 0 7.23333 0.544444 7.77778 1.16667 7.77778L 5.44444 7.77778L 5.44444 12.0556C 5.44444 12.6778 5.98889 13.2222 6.61111 13.2222C 7.23333 13.2222 7.77778 12.6778 7.77778 12.0556L 7.77778 7.77778L 12.0556 7.77778C 12.6778 7.77778 13.2222 7.23333 13.2222 6.61111C 13.2222 5.98889 12.6778 5.44444 12.0556 5.44444Z',
        minus:
            'M 12.0556 0L 7.77778 0L 5.44444 0L 1.16667 0C 0.544444 0 0 0.544444 0 1.16667C 0 1.78889 0.544444 2.33333 1.16667 2.33333L 5.44444 2.33333L 7.77778 2.33333L 12.0556 2.33333C 12.6778 2.33333 13.2222 1.78889 13.2222 1.16667C 13.2222 0.544444 12.6778 0 12.0556 0Z',
        image: [
            'M33.2554 0H4.74456C2.12886 0 0 2.13576 0 4.76087V27.2383C0 27.2419 0.000322133 27.2453 0.000644268 27.2485C0.000953515 27.2517 0.00126276 27.2549 0.00126276 27.2582L0.00253841 27.279C0.024495 29.8854 2.14196 32 4.74456 32H33.255C35.8627 32 37.9835 29.8786 37.9983 27.2654C37.9987 27.2616 37.9992 27.2573 37.9992 27.254C37.9992 27.2512 37.9994 27.2488 37.9996 27.2463C37.9998 27.2439 38 27.2415 38 27.2387V4.76087C38.0004 2.13576 35.872 0 33.2554 0ZM33.2554 30.6506H4.74456C2.95349 30.6506 1.48413 29.2533 1.35662 27.487L9.61622 18.4465C10.2192 17.7852 11.0205 17.4221 11.873 17.4221C12.7255 17.4221 13.5265 17.7852 14.1298 18.4465L22.5786 27.6946C22.7107 27.8386 22.8919 27.9132 23.0739 27.9132C23.236 27.9132 23.399 27.8539 23.5277 27.7357C23.8014 27.484 23.8195 27.0574 23.5691 26.7824L21.5538 24.5763L25.4235 20.4573C26.0387 19.8031 26.8574 19.4426 27.7289 19.4426C28.6004 19.4426 29.4187 19.8031 30.0343 20.4573L36.6442 27.4921C36.5133 29.2554 35.0453 30.6506 33.2554 30.6506ZM31.0109 19.5328L36.6561 25.5402L36.6565 4.76087C36.6565 2.87932 35.131 1.34814 33.2554 1.34814H4.74499C2.86945 1.34814 1.34437 2.87932 1.34437 4.76087V25.5054L8.62567 17.5348C9.48617 16.5929 10.6393 16.0739 11.873 16.0739C13.1059 16.0739 14.2594 16.5925 15.1204 17.5348L20.6423 23.5798L24.4452 19.5328C25.3163 18.6046 26.4825 18.094 27.7281 18.094C28.9736 18.094 30.1398 18.605 31.0109 19.5328Z',
            'M22.4012 9.25652C22.4012 6.63099 24.5297 4.49523 27.1462 4.49523C29.7624 4.49523 31.8912 6.63099 31.8912 9.25652C31.8912 11.8816 29.7624 14.0178 27.1462 14.0178C24.5297 14.0178 22.4012 11.8816 22.4012 9.25652ZM23.7452 9.25652C23.7452 11.1385 25.2707 12.6688 27.1462 12.6688C29.0213 12.6688 30.5469 11.1385 30.5469 9.25652C30.5469 7.37497 29.0213 5.8438 27.1462 5.8438C25.2703 5.8438 23.7452 7.37497 23.7452 9.25652Z',
        ],
        logout:
            'M0 1V0H1H17H18V1V3.875V5.8125L16.9062 6.875L16 7.8125V5.03125V2H2V24H16V22V21.0312V18.25H16.0625L16.9062 19.125L18 20.1875V21.0312V22V25V26H17H1H0V25V1ZM16.9062 9.71875L18.3438 8.28125L22.3438 12.2812L23.0312 13L22.3438 13.7188L18.3438 17.7188L16.9062 16.2812L19.1875 14H7V12H19.1875L16.9062 9.71875Z',
        pen:
            'M1.26799 43C1.00456 43 0.757004 42.9254 0.546066 42.7773C0.156174 42.5022 -0.0406037 42.0239 0.00700375 41.4645L0.803879 41.5322L0.00749203 41.4625L0.588303 34.7201C0.686203 33.5763 1.27653 31.9427 1.93132 31.0026L21.8488 2.39171C23.6144 -0.143933 27.101 -0.761609 29.6225 1.0128C32.1427 2.7882 32.7575 6.29467 30.9924 8.83019L11.0754 37.4405C10.4198 38.3813 9.09636 39.4979 8.06096 39.9814L1.96062 42.837L1.63396 42.1318L1.95866 42.838C1.72551 42.9465 1.49138 43 1.26799 43ZM28.7551 2.2589C26.9164 0.964587 24.3739 1.41613 23.0876 3.26415L20.6864 6.71422L27.3515 11.4079L29.7531 7.95934C31.04 6.11034 30.592 3.55321 28.7551 2.2589ZM19.8183 7.95922L26.4838 12.6529L11.0278 34.8562L4.36223 30.1616L19.8183 7.95922ZM3.17009 31.8749C2.67229 32.5901 2.16911 33.9805 2.09465 34.8509L1.53459 41.3592L7.42302 38.6029C8.21062 38.2341 9.33781 37.2838 9.83611 36.5686L10.1608 36.1018L3.49602 31.4076L3.17009 31.8749Z',
        calendar: [
            'M 2.90918 6.54545L 4.36373 6.54545L 4.36373 8L 2.90918 8L 2.90918 6.54545Z',
            'M 5.81836 6.54545L 7.2729 6.54545L 7.2729 8L 5.81836 8L 5.81836 6.54545Z',
            'M 8.72705 6.54545L 10.1816 6.54545L 10.1816 8L 8.72705 8L 8.72705 6.54545Z',
            'M 11.6362 6.54545L 13.0908 6.54545L 13.0908 8L 11.6362 8L 11.6362 6.54545Z',
            'M 2.90918 9.45455L 4.36373 9.45455L 4.36373 10.9091L 2.90918 10.9091L 2.90918 9.45455Z',
            'M 5.81836 9.45455L 7.2729 9.45455L 7.2729 10.9091L 5.81836 10.9091L 5.81836 9.45455Z',
            'M 8.72705 9.45455L 10.1816 9.45455L 10.1816 10.9091L 8.72705 10.9091L 8.72705 9.45455Z',
            'M 11.6362 9.45455L 13.0908 9.45455L 13.0908 10.9091L 11.6362 10.9091L 11.6362 9.45455Z',
            'M 13.8184 1.09091L 13.8184 1.45455L 16 1.45455L 16 16C 16 16.3636 15.6362 16.7273 15.2729 16.7273L 0.727051 16.7273C 0.36377 16.7273 0 16.3636 0 16L 0 1.45455L 2.18164 1.45455L 2.18164 1.09091C 2.18164 0.509091 2.69092 0 3.27295 0C 3.85449 0 4.36377 0.509091 4.36377 1.09091L 4.36377 1.45455L 11.6362 1.45455L 11.6362 1.09091C 11.6362 0.509091 12.1455 0 12.7271 0C 13.3091 0 13.8184 0.509091 13.8184 1.09091ZM 1.45459 15.2727L 14.5454 15.2727L 14.5454 5.09091L 1.45459 5.09091L 1.45459 15.2727Z',
            'M 2.90918 12.3636L 4.36373 12.3636L 4.36373 13.8182L 2.90918 13.8182L 2.90918 12.3636Z',
            'M 5.81836 12.3636L 7.2729 12.3636L 7.2729 13.8182L 5.81836 13.8182L 5.81836 12.3636Z',
            'M 8.72705 12.3636L 10.1816 12.3636L 10.1816 13.8182L 8.72705 13.8182L 8.72705 12.3636Z"/>',
        ],
    }

    switch (type) {
        case 'pdx':
            path = paths.pdx
            sizes = {
                default: { x: 59, y: 16 },
                small: { x: 38, y: 10 },
            }
            break
        case 'angle-down':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 }, small: { x: 11, y: 6 } }
            break
        case 'angle-left':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 } }
            inline = `
                transform: rotate(90deg);
            `
            break
        case 'angle-up':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 }, small: { x: 11, y: 6 } }
            inline = `
                transform: rotate(180deg);
            `
            break
        case 'angle-right':
            path = paths.angle
            sizes = { default: { x: 14, y: 8 } }
            inline = `
                transform: rotate(270deg);
            `
            break
        case 'close':
            path = paths.close
            sizes = { default: { x: 16, y: 16 }, small: { x: 10, y: 10 } }
            break
        case 'plus':
            path = paths.plus
            sizes = { default: { x: 14, y: 14 } }
            break
        case 'minus':
            path = paths.minus
            sizes = { default: { x: 14, y: 14 } }
            break
        case 'image':
            path = paths.image
            sizes = { default: { x: 38, y: 32 }, small: { x: 24, y: 18 } }
            attrs = { fillRule: 'evenodd', clipRule: 'evenodd' }
            break
        case 'logout':
            path = paths.logout
            sizes = { default: { x: 24, y: 26 } }
            break
        case 'pen':
            path = paths.pen
            sizes = { default: { x: 32, y: 43 }, small: { x: 15, y: 20 } }
            break
        case 'calendar':
            path = paths.calendar
            sizes = { default: { x: 16, y: 17 } }
            break
        default:
    }

    const Wrapper = styled.svg`
        ${inline};
    `
    const proportions = size && sizes.hasOwnProperty(size) ? sizes[size] : sizes.default

    return path ? (
        <Wrapper
            version="1.1"
            baseProfile="full"
            width={`${proportions.x}px`}
            height={`${proportions.y}px`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${sizes.default.x} ${sizes.default.y}`}
            x="0px"
            y="0px"
            className={className}
            {...attrs}
        >
            <g>
                {Array.isArray(path) ? (
                    path.map((p, i) => {
                        return <path d={p} key={`path_${i}`} />
                    })
                ) : (
                    <path d={path} />
                )}
            </g>
        </Wrapper>
    ) : null
}

Svg.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
}

export default Svg
