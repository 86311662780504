import React from 'react'
import PropTypes from 'prop-types'
import Button from '../input/Button'

class LogoutButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
        }
    }

    render() {
        const { auth, className } = this.props
        const { disabled } = this.state

        return (
            auth.isAuthenticated && (
                <Button
                    onClick={e => {
                        e.preventDefault()
                        this.setState({ disabled: true }, auth.logout)
                    }}
                    disabled={disabled}
                    iconRight="logout"
                    className={`header-link ${className}`.trim()}
                >
                    Kirjaudu ulos
                </Button>
            )
        )
    }
}

LogoutButton.propTypes = {
    auth: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
}

export default LogoutButton
