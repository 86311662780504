import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import trim from 'lodash/trim'
import Map from '../external/Map'
import { FormatNumber } from '../../utils/ConversionUtils'
import { ParseDate } from '../../utils/DateUtils'
import Button from '../input/Button'
import { Colors } from '../../styles/StyleConf'
import Loader from '../Loader'

const HousingInfo = ({ data }) => {
    if (!data)
        return (
            <Wrapper style={{ padding: '30px' }}>
                <Loader />
            </Wrapper>
        )

    const [lat, lon] = trim(data.google_map_coords, ['(', ')']).split(',')

    let showing1 = data.showing_begin_date ? ParseDate(data.showing_begin_date, 'd') : ''
    showing1 += data.showing_begin_time
        ? ` ${data.showing_begin_time.substring(0, data.showing_begin_time.lastIndexOf(':'))}`
        : ''
    showing1 += data.showing_end_time
        ? ` - ${data.showing_end_time.substring(0, data.showing_end_time.lastIndexOf(':'))}`
        : ''
    let showing2 = data.showing_begin_date2 ? ParseDate(data.showing_begin_date2, 'd') : ''
    showing2 += data.showing_begin_time2
        ? ` ${data.showing_begin_time2.substring(0, data.showing_begin_time2.lastIndexOf(':'))}`
        : ''
    showing2 += data.showing_end_time2
        ? ` - ${data.showing_end_time2.substring(0, data.showing_end_time2.lastIndexOf(':'))}`
        : ''

    return (
        <Wrapper>
            <Flex>
                <InfoTable>
                    <tbody>
                        {data.extra && (
                            <Row>
                                <Col colSpan="2">{data.extra}</Col>
                            </Row>
                        )}
                        {(showing1 || showing2) && (
                            <Row>
                                <Head>Esittely</Head>
                                <Col>
                                    {showing1 && <ColBlock>{showing1}</ColBlock>}
                                    {showing2 && <ColBlock>{showing2}</ColBlock>}
                                </Col>
                            </Row>
                        )}
                        {data.address && (
                            <Row>
                                <Head>Osoite</Head>
                                <Col>
                                    {data.address}
                                    {(data.zipcode || data.city) && (
                                        <ColBlock>
                                            {data.zipcode}
                                            {data.city && ` ${data.city}`}
                                        </ColBlock>
                                    )}
                                </Col>
                            </Row>
                        )}
                        {data.vatcode && (
                            <Row>
                                <Head>Y-tunnus</Head>
                                <Col>{data.vatcode}</Col>
                            </Row>
                        )}
                        {data.construction_end_year && (
                            <Row>
                                <Head>Valmistumisaika</Head>
                                <Col>
                                    {data.construction_end_month && `${data.construction_end_month}/`}
                                    {data.construction_end_year}
                                </Col>
                            </Row>
                        )}
                        {data.floorstotal && (
                            <Row>
                                <Head>Kerroksia</Head>
                                <Col>{data.floorstotal}</Col>
                            </Row>
                        )}
                        {data.buildingmaterial && (
                            <Row>
                                <Head>Rakennusaine</Head>
                                <Col>{data.buildingmaterial}</Col>
                            </Row>
                        )}
                        {data.roof && (
                            <Row>
                                <Head>Kattotyyppi/kate</Head>
                                <Col>{data.roof}</Col>
                            </Row>
                        )}
                        {data.warming && (
                            <Row>
                                <Head>Lämmitys</Head>
                                <Col>{data.warming}</Col>
                            </Row>
                        )}
                        {data.carslots && (
                            <Row>
                                <Head>Autopaikat</Head>
                                <Col>{data.carslots}</Col>
                            </Row>
                        )}
                        {data.commonarea && (
                            <Row>
                                <Head>Muut yhteiset tilat</Head>
                                <Col>{data.commonarea}</Col>
                            </Row>
                        )}
                        {data.target_flat_kitchen && (
                            <Row>
                                <Head>Keittiö</Head>
                                <Col>{data.target_flat_kitchen}</Col>
                            </Row>
                        )}
                        {data.target_flat_wc && (
                            <Row>
                                <Head>Kylpyhuone</Head>
                                <Col>{data.target_flat_wc}</Col>
                            </Row>
                        )}
                        {data.target_flat_balkony && (
                            <Row>
                                <Head>Parveke</Head>
                                <Col>{data.target_flat_balkony}</Col>
                            </Row>
                        )}
                        {data.target_flat_floor && (
                            <Row>
                                <Head>Pintamateriaalit</Head>
                                <Col>{data.target_flat_floor}</Col>
                            </Row>
                        )}
                        {data.target_flat_sauna && (
                            <Row>
                                <Head>Sauna</Head>
                                <Col>{data.target_flat_sauna}</Col>
                            </Row>
                        )}
                        {data.target_services && (
                            <Row>
                                <Head>Palvelut</Head>
                                <Col>{data.target_services}</Col>
                            </Row>
                        )}
                        {data.target_schools && (
                            <Row>
                                <Head>Koulut</Head>
                                <Col>{data.target_schools}</Col>
                            </Row>
                        )}
                        {data.target_transport && (
                            <Row>
                                <Head>Liikenneyhteydet</Head>
                                <Col>{data.target_transport}</Col>
                            </Row>
                        )}
                        {data.site_owns && (
                            <Row>
                                <Head>Tontin omistus</Head>
                                <Col>{data.site_owns}</Col>
                            </Row>
                        )}
                        {(data.site_info || data.rental_site_info) && (
                            <Row>
                                <Head>Tontin tiedot</Head>
                                <Col>
                                    {data.rental_site_info && data.rental_site_info}
                                    {data.site_info && ` ${data.site_info}`}
                                </Col>
                            </Row>
                        )}
                        {data.landlord && (
                            <Row>
                                <Head>Vuokranantaja</Head>
                                <Col>{data.landlord}</Col>
                            </Row>
                        )}
                        {data.rent_lastdate && (
                            <Row>
                                <Head>Vuokra-aika (asti)</Head>
                                <Col>{data.rent_lastdate}</Col>
                            </Row>
                        )}
                        {data.rent_peryear && (
                            <Row>
                                <Head>Vuokra per vuosi</Head>
                                <Col>{FormatNumber(data.rent_peryear)} €</Col>
                            </Row>
                        )}
                    </tbody>
                </InfoTable>
                <Links>
                    {data.target_targetnewlink && (
                        <a href={data.target_targetnewlink}>
                            <Button iconRight="angle-right">Uudiskohdelinkki</Button>
                        </a>
                    )}
                    {data.target_virtualpresentation && (
                        <a href={data.target_virtualpresentation}>
                            <Button iconRight="angle-right">Virtuaaliesittely</Button>
                        </a>
                    )}
                    {data.target_videoclip && (
                        <a href={data.target_videoclip}>
                            <Button iconRight="angle-right">Videolinkki</Button>
                        </a>
                    )}
                </Links>
            </Flex>
            {lon && lat && <MapWrapper coords={{ lon, lat }} />}
        </Wrapper>
    )
}

HousingInfo.propTypes = {
    data: PropTypes.object,
}

const breakpoints = {
    mobile: '500px',
    tablet: '768px',
}

const Wrapper = styled.div`
    background: ${Colors.brandWhite};
    padding: 20px;
`
const Flex = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
    }
`
const InfoTable = styled.table`
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
        width: 70%;
    }
`
const Row = styled.tr`
    vertical-align: top;
`
const Head = styled.th.attrs({ scope: 'row' })`
    text-align: left;
    vertical-align: top;
    display: block;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.mobile}) {
        display: table-cell;
        padding-right: 2em;
        padding-bottom: 1em;
        max-width: 10em;
        width: 30%;
    }
`
const Col = styled.td`
    padding-bottom: 1em;
    display: block;
    white-space: pre-wrap;

    @media (min-width: ${breakpoints.mobile}) {
        display: table-cell;
    }

    @media (min-width: ${breakpoints.tablet}) {
        padding-right: 10%;
    }
`
const ColBlock = styled.div``
const Links = styled.div`
    width: 10%;
    button {
        margin-bottom: 6px;
    }
`
const MapWrapper = styled(Map)`
    height: 300px;
    background-color: ${Colors.bgGray};
    margin-top: 10px;
`

export default HousingInfo
