import { format } from 'date-fns'

export const ParseDate = (date, type) => {
    const dateObj = date ? new Date(date) : new Date()

    let fmt
    switch (type) {
        case 'timestampSec':
            fmt = 'YYYYMDHmms'
            break
        case 'hm':
            fmt = 'H:mm'
            break
        case 'd':
            fmt = 'D.M.'
            break
        case 'D':
            fmt = 'D.M.YYYY'
            break
        default:
            fmt = 'D.M.YYYY H:mm'
    }

    return dateObj.toString() === 'Invalid Date' ? null : format(dateObj, fmt)
}

export const IsDate = input => {
    if (!input) return false
    var date = new Date(input)
    return date instanceof Date && !isNaN(date.valueOf())
}
