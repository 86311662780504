import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { loadModules } from 'esri-loader'

export class Map extends React.Component {
    constructor(props) {
        super(props)

        this.mapRef = React.createRef()
    }

    componentDidMount() {
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/Search', 'esri/widgets/Popup', 'esri/Graphic'], {
            css: true,
        }).then(([ArcGISMap, MapView, Search, Popup, Graphic]) => {
            const map = new ArcGISMap({
                basemap: 'streets',
            })

            const point = {
                type: 'point',
                longitude: this.props.coords.lon,
                latitude: this.props.coords.lat,
            }

            const markerSymbol = {
                type: 'picture-marker',
                url: '/images/marker_active.svg',
                width: '35px',
                height: '47px',
                yoffset: '20px',
            }

            const marker = new Graphic({
                geometry: point,
                symbol: markerSymbol,
            })

            this.popup = new Popup({
                dockOptions: {
                    buttonEnabled: false,
                },
            })

            this.view = new MapView({
                container: this.mapRef.current,
                map,
                center: [this.props.coords.lon, this.props.coords.lat],
                zoom: 15,
                popup: this.popup,
            })

            this.view.graphics.add(marker)

            this.search = new Search({
                view: this.view,
            })

            this.view.on('click', e => {
                this.search.clear()
                this.view.popup.clear()
                if (this.search.activeSource) {
                    const geocoder = this.search.activeSource.locator
                    const params = {
                        location: e.mapPoint,
                    }
                    geocoder.locationToAddress(params).then(
                        response => {
                            const address = response.address
                            showPopup(address, e.mapPoint)
                        },
                        err => console.error(err)
                    )
                }
            })

            const showPopup = (address, pt) => {
                this.view.popup.open({
                    title: Math.round(pt.longitude * 100000) / 100000 + ',' + Math.round(pt.latitude * 100000) / 100000,
                    content: address,
                    location: pt,
                })
            }
        })
    }

    componentWillUnmount() {
        if (this.view) {
            this.view.container = null
        }
    }

    render() {
        return (
            <Wrapper>
                <div className="webmap" ref={this.mapRef} style={{ width: '100%', height: '400px' }} />
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    .esri-popup__footer {
        display: none !important;
    }
`

Map.propTypes = {
    coords: PropTypes.shape({
        lon: PropTypes.string.isRequired,
        lat: PropTypes.string.isRequired,
    }),
    place: PropTypes.string,
    className: PropTypes.string,
}
export default Map
