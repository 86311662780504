import FetchLoader from './FetchLoader'
import Config from '../config/Config'

const options = {}

const timeout = (time, promise) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('Requst timed out'))
        }, time)
        promise.then(resolve, reject)
    })

export async function fetchRest(path, auth, payload, method = 'GET', params, defaults) {
    let headers = new Headers()
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    if (auth && auth.token) {
        headers.append('Token', auth.token)
    }
    if (auth && auth.apiKey) {
        headers.append('Authorization', `Bearer ${auth.apiKey}`)
    }

    defaults = Object.assign({}, { extended: 1, remove_empty: 1 }, defaults)

    let urlParams = '?'
    if (params && params.length) {
        urlParams += params.join('&')
    }
    for (let key in defaults) {
        urlParams += `&${key}=${defaults[key]}`
    }
    let url = `${Config.api.scheme}://${Config.api.domain}${Config.api.path}${path}${urlParams}`

    const request = new Request(url, {
        headers,
        body: JSON.stringify(payload),
        method,
    })

    return await timeout(Config.api.timeout, fetch(request, options))
        .then(res => res.json())
        .then(res => FetchLoader(res, res.error, res.debug, auth, path))
        .catch(err => console.error(err))
}
