import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import Select from '../input/Select'
import { Label } from '../../styles/Headings'
import Req from '../input/Req'
import Button from '../input/Button'
import { Colors } from '../../styles/StyleConf'
import config from '../../config/Config'

class ReservationForm extends React.Component {
    constructor(props) {
        super(props)

        this.typeOptions =
            this.props.reservees && this.props.reservees.length
                ? [config.reservations.typeOptions[0]]
                : config.reservations.typeOptions.slice(1)

        if (this.props.reservation) {
            this.typeOptions = this.props.reservation.lead
                ? config.reservations.typeOptions
                : [config.reservations.typeOptions[0]]
        }

        if (this.props.housingStatus !== 'Myynnissä') {
            this.typeOptions = [...this.typeOptions].filter(option => option.status < 3)
        }

        if (this.props.customers) {
            this.customerOptions = [
                { label: 'Lisää uusi asiakas', value: 'new' },
                ...this.props.customers.map(customer => ({
                    label: customer.person_name,
                    value: String(customer.id),
                    disabled: this.props.reservees && this.props.reservees.includes(customer.id),
                })),
            ].filter(item => item.label && item.value)
        } else {
            this.customerOptions = [{ label: 'Lisää uusi asiakas', value: 'new' }].filter(
                item => item.label && item.value
            )
        }

        this.customerFields = [
            { label: 'Sähköposti', value: 'email', req: true },
            { label: 'Etunimi', value: 'forename', req: true },
            { label: 'Sukunimi', value: 'surname', req: true },
            { label: 'Osoite', value: 'address' },
            { label: 'Postinumero', value: 'zipcode' },
            { label: 'Kaupunki', value: 'city' },
            { label: 'Puhelin', value: 'telephone', req: true },
        ]

        this.submitForm = this.submitForm.bind(this)
        this.setValue = this.setValue.bind(this)
        this.setValueFromEvent = this.setValueFromEvent.bind(this)
        this.setCustomer = this.setCustomer.bind(this)
        this.getCustomerById = this.getCustomerById.bind(this)
        this.validateFields = this.validateFields.bind(this)
        this.deleteReservation = this.deleteReservation.bind(this)
        this.setDerivedData = this.setDerivedData.bind(this)

        this.state = {
            type: this.typeOptions.length === 1 ? this.typeOptions[0].value : null,
            customer:
                this.props.reservation && this.props.reservation.company_customer
                    ? this.props.reservation.company_customer
                    : null,
            customer_id: null,
            new_customer: this.props.customers && this.props.customers.length > 0 ? null : 'new',
            submitted: false,
            deleting: false,
            error: null,
        }
    }

    submitForm(e) {
        e.preventDefault()

        if (this.validateFields()) {
            this.setState({ submitted: true }, () => {
                const type = this.state.type
                const customer_id = this.state.customer_id
                const payload = { type, customer_id }

                if (this.state.new_customer === 'new') {
                    const customerPayload = this.state.customer

                    this.props.createCustomer(customerPayload).then(res => {
                        if (res.error) {
                            let error
                            if (res.error.message.includes('Invalid email address')) {
                                error = 'Virheelinen sähköpostiosoite'
                            } else if (res.error.message.includes('Invalid telephone number')) {
                                error = 'Virheelinen puhelinnumero'
                            } else if (res.error.message.includes('Invalid zipcode')) {
                                error = 'Virheelinen postinumero'
                            } else if (res.error.message.includes('Company Customer already Exists')) {
                                error = 'Asiakas on jo olemassa'
                            } else if (res.error.message.includes('Customer already has reservation on queue')) {
                                error = 'Asiakkaalla on jo varaus listassa'
                            } else {
                                error = 'Virhe syötteessä'
                            }
                            this.setState({ error, submitted: false })
                        } else {
                            payload.customer_id = res.data.id
                            this.props.postReservation(payload)
                        }
                    })
                } else {
                    const customer = this.getCustomerById(this.state.customer_id)
                    const stateCustomer = this.state.customer
                    const reservationListEmpty = this.props.reservation === null
                    const id = reservationListEmpty ? null : this.props.reservation.reservation_id

                    if (!isEqual(customer, stateCustomer)) {
                        this.props.modifyCustomer(this.state.customer_id, stateCustomer).then(() => {
                            if (reservationListEmpty) this.props.postReservation(payload)
                            else this.props.modifyReservation({ id, type })
                        })
                    } else {
                        if (reservationListEmpty) this.props.postReservation(payload)
                        else this.props.modifyReservation({ id, type })
                    }
                }
            })
        } else {
            this.setState({ error: 'Täytä pakolliset kentät, kiitos' })
        }
    }

    deleteReservation() {
        this.setState({ deleting: true }, () => {
            if (window.confirm('Haluatko varmasti poistaa varauksen?')) {
                this.props.removeReservation({
                    id: this.props.reservation.reservation_id,
                })
            } else {
                this.setState({ deleting: false })
            }
        })
    }

    setValue(key, value) {
        if (key && value !== null) {
            this.setState({
                [key]: value,
            })
        }
    }

    setValueFromEvent(e) {
        const key = e.target.name
        const value = e.target.value
        let customer = this.state.customer

        if (customer === null) {
            customer = {
                email: null,
                forename: null,
                surname: null,
                address: null,
                zipcode: null,
                city: null,
                telephone: null,
            }
        }

        if (key && value !== null) {
            customer[key] = value
            this.setState({ customer })
        }
    }

    setCustomer(value) {
        if (value === 'new') {
            const state = {
                new_customer: 'new',
            }
            this.customerFields.forEach(field => (state[field.value] = ''))
            this.setState(state)
        } else if (value) {
            const customer = this.props.customers.filter(n => String(n.id) === String(value))

            if (customer[0]) {
                this.setState(
                    {
                        customer_id: value,
                        new_customer: null,
                        customer: customer[0],
                    },
                    () => this.setDerivedData(value)
                )
            }
        }
    }

    getCustomerById(id) {
        const filtered = this.props.customers.filter(customer => String(customer.id) === id)
        return filtered ? filtered[0] : null
    }

    validateFields() {
        let validated = true

        this.customerFields.filter(field => field.req).forEach(field => {
            if (!this.state.customer[field.value]) validated = false
        })
        if (!this.state.type) validated = false

        return validated
    }

    setDerivedData(customerId) {
        const customer_id = customerId || this.props.reservation.company_customer.id
        const state = this.props.reservation
            ? {
                  customer_id,
                  type: this.props.reservation.type,
              }
            : {}

        const customer = this.getCustomerById(customer_id)

        if (customer) Object.keys(customer).forEach(key => key && (state[key] = customer[key]))

        this.setState(state)
    }

    UNSAFE_componentWillMount() {
        if (this.props.reservation) this.setDerivedData()
    }

    render() {
        const { reservation } = this.props
        const { type, customer_id, new_customer, submitted, deleting, error } = this.state

        return (
            <Wrapper>
                <Form onSubmit={this.submitForm}>
                    <StyledLabel htmlFor="type">
                        Varaustyyppi <Req />
                    </StyledLabel>
                    <StyledSelect
                        id="type"
                        options={this.typeOptions}
                        searchable={false}
                        callback={val => val && this.setValue('type', val.value)}
                        value={type}
                        clearable={false}
                    />
                    <StyledLabel htmlFor="customer_id">
                        Asiakas <Req />
                    </StyledLabel>
                    <StyledSelect
                        id="customer_id"
                        options={this.customerOptions}
                        searchable={this.customerOptions.length > 1}
                        callback={val => val && this.setCustomer(val.value)}
                        value={new_customer || customer_id}
                        clearable={false}
                        disabled={!!this.props.reservation}
                    />
                    {(new_customer || customer_id) &&
                        this.customerFields.map(field => {
                            return (
                                <React.Fragment key={`customer_input_wrapper_${field.label}`}>
                                    <StyledLabel htmlFor={field.value}>
                                        {field.label} {field.req && <Req />}
                                    </StyledLabel>
                                    <StyledText
                                        key={`customer_input_wrapper_${field.label}`}
                                        id={field.value}
                                        name={field.value}
                                        value={this.state.customer ? this.state.customer[field.value] : ''}
                                        onChange={this.setValueFromEvent}
                                    />
                                </React.Fragment>
                            )
                        })}
                    {error && <Error>{error}</Error>}
                    <Actions reservation={reservation}>
                        {reservation && (
                            <Button
                                iconRight="close"
                                disabled={reservation.type > 3 || deleting}
                                onClick={this.deleteReservation}
                            >
                                {deleting ? 'Poistetaan...' : 'Poista'}
                            </Button>
                        )}
                        {reservation && (
                            <Submit iconRight="pen" disabled={submitted}>
                                {submitted ? 'Muokataan...' : 'Muokkaa'}
                            </Submit>
                        )}
                        {!reservation && (
                            <Submit iconRight="plus" disabled={submitted}>
                                {submitted ? 'Lisätään...' : 'Lisää varaus'}
                            </Submit>
                        )}
                    </Actions>
                </Form>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    padding: 10px 15px;
    height: 650px;
    overflow: hidden;
`
const Actions = styled.div`
    margin-top: 20px;
    display: flex;
    ${({ reservation }) => (reservation ? 'justify-content:space-between;' : 'justify-content:flex-end;')};
`

const StyledLabel = styled(Label)`
    display: block;
    margin-bottom: 4px;
    font-weight: bold;
    text-transform: uppercase;
`
const StyledSelect = styled(Select)`
    display: block;
    margin-bottom: 10px;
`
const StyledText = styled.input.attrs({ type: 'text' })`
    display: block;
    margin-bottom: 10px;
    width: calc(100% - 6px);
    height: 26px;
    margin-right: 6px;
    border: 1px solid ${Colors.uiGray};
    text-indent: 0.8em;
`
const Form = styled.form`
    min-height: 70vh;
`
const Submit = styled(Button)``
const Error = styled.div`
    color: ${Colors.errorRed};
    margin: 1em 0;
`

ReservationForm.propTypes = {
    reservees: PropTypes.number,
    createCustomer: PropTypes.func.isRequired,
    modifyCustomer: PropTypes.func.isRequired,
    postReservation: PropTypes.func.isRequired,
    modifyReservation: PropTypes.func.isRequired,
    removeReservation: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired,
    reservation: PropTypes.object,
    housingStatus: PropTypes.string,
}

ReservationForm.defaultProps = {
    customers: [],
}

export default ReservationForm
