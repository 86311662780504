import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import HousingList from '../views/HousingList'
import HousingWrapper from '../views/HousingWrapper'
import Login from './../login/Login'

const MainNavigation = () => {
    const ProtectedRoute = ({ component: Component, auth, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated ? (
                    <Component {...props.match.params} auth={auth} />
                ) : (
                    <Redirect
                        to={{ pathname: `/${auth.installation}/kirjaudu`, state: { referrer: props.location } }}
                        auth={auth}
                    />
                )
            }
        />
    )

    const NormalRoute = ({ component: Component, auth, ...rest }) => (
        <Route {...rest} render={props => <Component {...props.match.params} auth={auth} />} />
    )

    NormalRoute.propTypes = ProtectedRoute.propTypes = {
        component: PropTypes.func.isRequired,
        location: PropTypes.object,
        auth: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.object,
        }),
    }

    return (
        <AuthContext.Consumer>
            {auth => (
                <Router>
                    <React.Fragment>
                        <ProtectedRoute path="/:installation/taloyhtiot" component={HousingList} auth={auth} />
                        <ProtectedRoute path="/:installation/varaukset/:id" component={HousingWrapper} auth={auth} />
                        <NormalRoute path="/:installation/kirjaudu" component={Login} auth={auth} />
                        <NormalRoute exact path="/:installation/" component={Login} auth={auth} />
                    </React.Fragment>
                </Router>
            )}
        </AuthContext.Consumer>
    )
}

export default MainNavigation
